import { FC, useState } from 'react';
import { HStack, Button, Box } from '@chakra-ui/react';
import { SectionTitle } from './SectionTitle';
import { SectionDescription } from './SectionDescription';
import { ConfirmDeleteModal } from './ConfirmDelete.modal';
import {
    useGetCurrentUserQuery,
    useGetCurrentUserWorkspacesQuery,
} from '@core/api';
import { useGetWorkspaceId } from '../../../hooks';
import { trpc } from '@core/api/trpc';
import { Alerter } from '../../../utils';

type DangerZoneProps = {};

export const DangerZone: FC<DangerZoneProps> = (props) => {
    const [showDeleteWorkspaceModal, setShowDeleteWorkspaceModal] =
        useState(false);

    const workspaceId = useGetWorkspaceId();

    const deleteWorkspaceMutation = trpc.workspace.deleteWorkspace.useMutation({
        mutationKey: ['delete_workspace'],
        onSuccess: () => Alerter.success('Workspace deleted'),
        onError: (error) => Alerter.error(error.message),
    });

    const getCurrentUserQuery = useGetCurrentUserQuery({ enabled: false });

    const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery({
        enabled: false,
    });

    const handleDeleteWorkspace = async () => {
        await deleteWorkspaceMutation.mutateAsync({
            workspaceId: workspaceId,
        });

        await getCurrentUserQuery.refetch();

        await getCurrentUserWorkspacesQuery.refetch();

        setShowDeleteWorkspaceModal(false);
    };

    return (
        <Box>
            <SectionTitle>Danger zone</SectionTitle>
            <SectionDescription mt="12px" mb="16px">
                All your data will be removed. This action can’t be undone
            </SectionDescription>
            <HStack spacing="16px">
                <DeleteWorkspaceButton
                    onClick={() => {
                        setShowDeleteWorkspaceModal(true);
                    }}
                    isLoading={false}
                />
            </HStack>
            <ConfirmDeleteModal
                onClose={() => {
                    setShowDeleteWorkspaceModal(false);
                }}
                isOpen={showDeleteWorkspaceModal}
                onConfirm={() => {
                    handleDeleteWorkspace();
                }}
                confirmLoading={deleteWorkspaceMutation.isPending}
                title="Delete workspace"
                description="This will permanently delete this workspace and all the data associated. This action is not reversible, do you want to continue?"
                confirmLabel="Delete workspace"
            />
        </Box>
    );
};

const DeleteWorkspaceButton: FC<{
    onClick: () => void;
    isLoading: boolean;
}> = (props) => {
    return (
        <Button
            colorScheme="red"
            variant="outline"
            borderColor="red.20"
            color="red.DEFAULT"
            borderRadius="4px"
            onClick={props.onClick}
            isLoading={props.isLoading}
        >
            Delete workspace
        </Button>
    );
};
