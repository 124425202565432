import { useCallback, useMemo } from 'react';
import {
    Box,
    GridItem,
    HStack,
    IconButton,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomTag, Icon } from '../../../components';
import { BoardCardItemResponse, BoardFilterValue } from '../types';
import {
    getColumnDragBackgroundColor,
    sortCardsByDateAndAccess,
    getTaskStatusByType,
    getUnreadCards,
    getUnansweredCards,
    getActiveTasksCards,
    getTeamActivityCards,
    getMentionedCards,
} from '../../../utils';
import { ApiResponse, CardStatusItem } from '../../../types';
import {
    setCardsFilterId,
    setCardsStatusId,
} from '../../../redux/reducers/page-navigation-state.reducer';
import { addRecentEntry } from '../../../redux/reducers/search-recent-stack.reducer';
import { useGetWorkspaceId } from '../../../hooks';
import { useAtomValue } from 'jotai';
import { tasksAtom } from '@atoms/tasksAtom';
import DraggableTaskCard from './DraggableTaskCard';
import { useChats } from '../../../hooks/useChats';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../constants';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useCardFilters } from './FilterSelect/useCardFilters';
interface KanbanBoardColumnProps {
    tabIndex: BoardFilterValue;
    columnStatus: CardStatusItem;
    enableColumnIcon?: boolean;
    isColumnIconActive?: boolean;
    onClickColumnIcon?: VoidFunction;
    chats: ReturnType<typeof useChats>;
}

export const KanbanBoardColumn = ({
    tabIndex,
    columnStatus,
    enableColumnIcon,
    isColumnIconActive,
    onClickColumnIcon,
    chats,
}: KanbanBoardColumnProps) => {
    const [animateParent] = useAutoAnimate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaceId = useGetWorkspaceId();
    const queryClient = useQueryClient();
    const filters = useCardFilters()
    const {
        name: statusName,
        id: statusId,
        typeId: statusSystemDefaultId,
        position,
    } = columnStatus;

    const teamTgIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    const dataByColumnType = useMemo(
        () => getTaskStatusByType({ position, typeId: statusSystemDefaultId }),
        [position, statusSystemDefaultId],
    );

    const onRedirect = (obj: {
        cardData: BoardCardItemResponse;
        statusId?: number;
        filterId?: number;
    }) => {
        navigate(`/${workspaceId}/chat/${obj.cardData.cardId}`, {
            state: {
                statusId: obj.statusId,
                filterId: obj.filterId,
                chatTelegramId: obj.cardData.chatTelegramId,
            },
        });
        dispatch(setCardsStatusId(obj.statusId || 0));
        dispatch(setCardsFilterId(obj.filterId || 1));
        dispatch(addRecentEntry(obj.cardData));
    };

    const tasks = useAtomValue(tasksAtom);
    const items = tasks[statusId];

    const getFilteredCards = useCallback(() => {
        let res: BoardCardItemResponse[] = [];
        const cards = items || [];
        switch (tabIndex) {
            case BoardFilterValue.All:
                res = cards
                break;
            case BoardFilterValue.Unread:
                res = getUnreadCards({
                    cards,
                    chats,
                });
                break;
            case BoardFilterValue.Unanswered:
                res = getUnansweredCards({
                    cards,
                    chats,
                    teamTelegramIds: teamTgIds?.value?.telegramUserIds || [],
                });
                break;
            case BoardFilterValue.ActiveTasks:
                res = getActiveTasksCards({
                    cards,
                });
                break;
            case BoardFilterValue.TeamActivity:
                res = getTeamActivityCards({
                    cards,
                });
                break;
            case BoardFilterValue.Mentions:
                res = getMentionedCards({
                    cards,
                });
                break;
            default:
                res = [];
        }

        res = res.filter(card => filters.applyFilters(card, chats));

        const sortedCards = res?.sort((cardA, cardB) =>
            sortCardsByDateAndAccess(
                cardA,
                cardB,
                chats.data.chats,
                chats.data.messages,
                chats.data.lastMessages,
            ),
        );

        return sortedCards;
    }, [tabIndex, items, chats, teamTgIds]);

    // Pre-calculate background colors
    const backgroundColors = useMemo(
        () => ({
            default: 'transparent',
            draggingOver: getColumnDragBackgroundColor(true, false),
            draggingFrom: getColumnDragBackgroundColor(false, true),
        }),
        [],
    );

    const sortedTasks = getFilteredCards();
    return (
        <GridItem
            p={2}
            borderRightWidth="1px"
            borderRightColor="gray.15"
            height="100%"
        >
            <VStack align="flex-start" w="100%" spacing={1.5} height="100%">
                <HStack justify="space-between" spacing={2} w="full">
                    <HStack spacing={2}>
                        <CustomTag
                            label={statusName}
                            bgColor={dataByColumnType.bgColor}
                            labelColor={dataByColumnType.textColor}
                        />
                        {sortedTasks.length ? (
                            <Text>{sortedTasks.length}</Text>
                        ) : null}
                    </HStack>
                    {enableColumnIcon ? (
                        <IconButton
                            aria-label={
                                isColumnIconActive
                                    ? 'hide column cards'
                                    : 'show column cards'
                            }
                            minW="auto"
                            boxSize="24px"
                            variant="outline"
                            borderRadius="4px"
                            bg="transparent"
                            onClick={onClickColumnIcon}
                        >
                            <Icon
                                name={isColumnIconActive ? 'eye-off ' : 'eye'}
                                height="16"
                                width="16"
                            />
                        </IconButton>
                    ) : null}
                </HStack>
                <Box w="100%" h="100%">
                    <Droppable droppableId={`${statusId}`} type="tasks">
                        {(provided, snapshot) => (
                            <Box
                                w="100%"
                                h="100%"
                                ref={provided.innerRef}
                                transition="background .15s ease-in-out"
                                bg={
                                    snapshot.isDraggingOver
                                        ? backgroundColors.draggingOver
                                        : snapshot.draggingFromThisWith
                                          ? backgroundColors.draggingFrom
                                          : backgroundColors.default
                                }
                                {...provided.droppableProps}
                            >
                                <div ref={animateParent}>
                                    {sortedTasks?.map((task, index) => {
                                        if (
                                            enableColumnIcon &&
                                            isColumnIconActive
                                        ) {
                                            return null;
                                        }

                                        const chat = chats.getChatInfo(
                                            task.chatTelegramId.toString(),
                                        );

                                        return (
                                            <DraggableTaskCard
                                                key={task.cardId}
                                                task={task}
                                                index={index}
                                                columnStatusId={columnStatus.id}
                                                tabIndex={tabIndex}
                                                onRedirect={onRedirect}
                                                chat={chat}
                                                chats={chats}
                                            />
                                        );
                                    })}
                                </div>
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </Box>
            </VStack>
        </GridItem>
    );
};
