import { useGetCurrentUserQuery, useGetCurrentUserWorkspacesQuery, useSetCurrentWorkspaceMutation } from '@core/api';
import { Avatar } from '@chakra-ui/react';

import css from './index.module.css';
import { trpc } from '@core/api/trpc';
import { Alerter } from '../../../../utils';

const Selector = () => {
	const getCurrentUserQuery = useGetCurrentUserQuery({ enabled: false });

    const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();

    const setOnboardingStatus = trpc.users.setOnboardingStatus.useMutation({
        mutationKey: ['set_onboarding_status'],
        onSuccess: () => {},
        onError: () => {},
    
    });

    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation();

    if (getCurrentUserWorkspacesQuery.isError) return <>error</>;

    if (getCurrentUserWorkspacesQuery.isLoading) return <>loading</>;

    if (!getCurrentUserWorkspacesQuery.data?.length) return <>empty</>;

    const handleSelectWorkspace = async (workspaceId: number) => {
        try {
            await setCurrentWorkspaceMutation.mutateAsync({ workspaceId });
            await setOnboardingStatus.mutateAsync({ onboardingStatus: true });
            await getCurrentUserQuery.refetch();
        } catch (error) {
            Alerter.error('Failed to select workspace. Please try again.');
            console.error('Error selecting workspace:', error);
        }
    }

    return (
        <div className={css.nav}>
            {getCurrentUserWorkspacesQuery.data.map((workspace) => (
                <button
                    key={workspace.id}
                    className={css.selectorItem}
                    onClick={() => handleSelectWorkspace(workspace.id)}
                >
                    <Avatar
                        size='2xs'
                        name={workspace.name}
                        src={workspace.avatarThumbnailUrl}
                        className={css.selectorItemAvatar}
                    />

                    <span>
                        {workspace.name}
                    </span>
                </button>
            ))}
        </div>
    );
};

export default Selector;
