import React, { useCallback, useState, useRef } from 'react';
import {
	Box,
	Flex,
	HStack,
	Text,
	Input,
	useOutsideClick,
} from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';

interface ChatInfoSectionProps {
	cardId: number;
	cardOwnerQuery: any;
	initialInfo?: string;
	onInfoChange?: (info: string) => void;
}

export const ChatInfoSection: React.FC<ChatInfoSectionProps> = ({
	cardId,
	cardOwnerQuery,
	onInfoChange,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [info, setInfo] = useState(cardOwnerQuery.data.card.chatInfo);
	const inputRef = useRef<HTMLDivElement>(null);

	const handleInfoChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setInfo(newValue);
	}, []);

	const handleSave = useCallback(() => {
		setIsEditing(false);
		onInfoChange?.(info);
	}, [info, onInfoChange]);

	useOutsideClick({
		ref: inputRef,
		handler: handleSave,
	});

	const handleFieldClick = useCallback(() => {
		if (!isEditing) {
			setIsEditing(true);
		}
	}, [isEditing]);

	return (
		<HStack
			p="4px 12px"
			borderRadius="4px"
			borderWidth="1px"
			borderColor="gray.20"
			align="flex-start"
			spacing={1}
		>
			<HStack spacing={1} pt="8px" pb="4px">
				<Icon
					name="info-circle"
					width="16px"
					height="16px"
					color={colors.tertiary}
				/>
				<Text variant="bodyMedium" color="tertiary">
					Info
				</Text>
			</HStack>
			<Box
				ref={inputRef}
				w="full"
				h="100%"
				bg="transparent"
				transition="0.3s background ease"
				borderRadius="4px"
				p="6px 8px"
				minH="32px"
				onClick={handleFieldClick}
				cursor="text"
			>
				<Flex alignItems="center" gap={2}>
					{isEditing ? (
						<Input
							autoFocus
							value={info}
							onChange={handleInfoChange}
							size="sm"
							borderRadius="4px"
							_focus={{
								borderColor: 'blue.45',
								boxShadow: 'blue.light',
							}}
							placeholder="Enter info..."
						/>
					) : (
						<Text
							w="full"
							fontSize="12px"
							lineHeight="20px"
							color="secondary"
						>
							{info || 'No info added'}
						</Text>
					)}
				</Flex>
			</Box>
		</HStack>
	);
};
