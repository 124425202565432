import React from 'react';
import { Skeleton, Stack } from '@chakra-ui/react';
import { LabelItemType } from '../../../types';
import { CustomTag } from '../../CustomTag/CustomTag';
import { getLabelInfoByType } from '../../../utils';

interface CardLabelsProps {
	isLoaded: boolean;
	data: LabelItemType[];
}

export const CardLabels: React.FC<CardLabelsProps> = ({ isLoaded, data }) => {
	if (!data.length) {
		return null;
	}
	if (!isLoaded) {
		return (
			<Skeleton
				isLoaded={false}
				maxW="100%"
				w="100%"
				startColor="gray.40"
				endColor="gray.20"
				h="16px"
				mb={2}
			/>
		);
	}

	return (
		<Stack direction="row" spacing={1} maxW="full" flexWrap="wrap" pb={2}>
			{data.map(el => {
				const { bgColor, textColor } = getLabelInfoByType(el.colourId);
				return (
					<CustomTag
						key={el.workspaceLabelId}
						label={el.name}
						bgColor={bgColor}
						labelColor={textColor}
						wrapperProps={{
							h: '16px',
							minH: '16px',
							px: "8px",
							py: "5.5px",
							minW: 'auto',
							maxW: '120px',
						}}
						labelProps={{
							lineHeight: '13.5px',
							fontSize: '9px',
							fontWeight: 400,
							title: el.name,
						}}
					/>
				);
			})}
		</Stack>
	);
};
