// context.tsx
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { SupabaseClient } from '@supabase/supabase-js';
import { Database } from '../../types/supabase.types';
import axios from 'axios';
import { createSupabaseClient } from './client';

const ctx = createContext<SupabaseClient<Database> | null>(null);

export const SupabaseContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { accessToken: legacyToken } = useSelector(
    (state: RootStore) => state.auth.token,
  );

  const [supabaseClient] = useState(() => createSupabaseClient());

  useEffect(() => {
    let unsubscribe = () => {};
    (async () => {
      if (!legacyToken) return;

      const supabaseToken = await exchangeSupabaseToken(legacyToken);
      if (!supabaseToken) return;

      const { data, error } = await supabaseClient.auth.setSession({
        access_token: supabaseToken,
        refresh_token: '',
      });

      if (error) {
        console.error('Error setting session', error);
      }

      unsubscribe = supabaseClient.auth.onAuthStateChange((event, session) => {
        if (event === 'TOKEN_REFRESHED' && session) {
          supabaseClient.auth.setSession({
            access_token: session.access_token,
            refresh_token: session.refresh_token ?? '',
          });
        }
      }).data.subscription.unsubscribe;
    })();


    return () => {
      unsubscribe();
    };
  }, [legacyToken, supabaseClient]);

  return <ctx.Provider value={supabaseClient}>{children}</ctx.Provider>;
};

export const useSupabase = () => {
  const supabase = useContext(ctx);
  if (!supabase) {
    throw new Error('useSupabase must be used within a SupabaseContextProvider');
  }
  return supabase;
};

type ExchangeTokenResponse = {
  access_token: string;
};

const exchangeEndpoint = import.meta.env.VITE_SUPABASE_EXCHANGE_TOKEN_URL;

async function exchangeSupabaseToken(legacyToken: string): Promise<string | null> {
  try {
    const response = await axios.get<ExchangeTokenResponse>(exchangeEndpoint, {
      headers: {
        Authorization: `Bearer ${legacyToken}`,
      },
    });
    return response.data.access_token;
  } catch (error) {
    console.error('Failed to exchange Supabase token', error);
    return null;
  }
}
