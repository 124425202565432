import { CustomTag } from '../../../components';

import { Filters, useCardFilters } from './FilterSelect/useCardFilters';
import { FilterSelect, FilterSelectItem } from './FilterSelect/FilterSelect';
import { Text } from '@chakra-ui/react';
import { IconCalendarEvent } from '@tabler/icons-react';

type TimeFilter = {
    label: string;
} & Filters['lastActivity'];

export const LastActivitySelect = () => {
    const filters = useCardFilters();

    const lastActivity = filters.getFilterValue('lastActivity');

    const selectedLastActivity = timeFilters.find(
        (x) =>
            x.operator === lastActivity?.operator &&
            x.timeMs === lastActivity.timeMs,
    );

    return (
        <FilterSelect
            title="Last Activity"
            items={timeFilters.map((x) => {
                return (
                    <FilterSelectItem
                        key={x.label}
                        onClick={() => {
                            filters.addFilter('lastActivity', {
                                operator: x.operator,
                                timeMs: x.timeMs,
                            });
                        }}
                    >
                        <Text color={'secondary'}>{x.label}</Text>
                    </FilterSelectItem>
                );
            })}
            isLoading={false}
            selectedItem={
                selectedLastActivity ? (
                    <CustomTag
                        label={selectedLastActivity.label}
                        bgColor={'transparentLight.5'}
                        labelColor={'primary'}
                        enableCloseButton={true}
                        wrapperProps={{
                            pr: '3px',
                            maxW: '160px',
                        }}
                        closeButtonProps={{
                            'aria-label': 'last activity filter',
                            onClick: (ev) => {
                                ev.stopPropagation();
                                filters.removeFilter('lastActivity');
                            },
                        }}
                    />
                ) : null
            }
            icon={<IconCalendarEvent height="16" width="16" />}
        />
    );
};

const enum TimeMs {
    Second = 1000,
    Minute = 60 * Second,
    Hour = 60 * Minute,
    Day = 24 * Hour,
    Week = 7 * Day,
    Month = 30 * Day,
    Year = 365 * Day,
}

const timeFilters: TimeFilter[] = [
    {
        label: '< a week ago',
        timeMs: TimeMs.Week,
        operator: 'lt',
    },
    {
        label: '< a month ago',
        timeMs: TimeMs.Month,
        operator: 'lt',
    },
    {
        label: '< 3 months ago',
        timeMs: 3 * TimeMs.Month,
        operator: 'lt',
    },
    {
        label: '> a week ago',
        timeMs: TimeMs.Week,
        operator: 'gt',
    },
    {
        label: '> a month ago',
        timeMs: TimeMs.Month,
        operator: 'gt',
    },
    {
        label: '> 3 months ago',
        timeMs: 3 * TimeMs.Month,
        operator: 'gt',
    },
    {
        label: '> 6 months ago',
        timeMs: 6 * TimeMs.Month,
        operator: 'gt',
    },
    {
        label: '> 12 months ago',
        timeMs: TimeMs.Year,
        operator: 'gt',
    },
];
