import { useMutation } from '@tanstack/react-query';
import API from '../api/api.base';
import { ApiResponseEmpty, UpdateWorkspaceCardPayload } from '../types';
import { Alerter } from '../utils';
import { QueryKeys } from '../constants';

export const useUpdateWorkspaceCardStatus = () => {
	return useMutation<ApiResponseEmpty, unknown, UpdateWorkspaceCardPayload>({
		mutationKey: [QueryKeys.UPDATE_WORKSPACE_CARD_STATUS],
		mutationFn: payload => {
			const { workspaceId, cardId, statusId } = payload;
			return API.post(`/api/workspaces/${workspaceId}/cards/${cardId}`, {
				statusId,
			});
		},
		onSuccess: data => {
			if (!data.success) {
				return;
			}

			// Alerter.success('Card status changed successfully!');
		},
	});
};
