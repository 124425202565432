import { useQuery } from '@tanstack/react-query';
import { LegacyApiResponse } from '../axios';
import { queryClientForApi } from '../query-client';
import axios from 'axios';

export type Card = {
    cardId: number;
    cardName: string;
    chatTelegramId: number;
    tasksCount: number;
    hasAccess: boolean;
    trackedLastTelegramMessageUnixTime: number | null;
    status: {
        id: number;
        name: string;
        typeId: number;
        position: number;
    };
    message: {
        telegramUserId: number;
        senderId: number;
        senderName: string;
        text: string;
        unreadCount: number;
        activityTypeId: number | null;
        workspaceCardActivityId: number | null;
    } | null;
    currentUserTagged: boolean;
    labels: {
        workspaceLabelId: number;
        name: string;
        colourId: number;
    }[];
}

export type WorkspaceCardsResult = {
    cards: Card[];
};

const getWorkspaceCards = async (workspaceId: string) => {
	const { data } = await axios.get<LegacyApiResponse<WorkspaceCardsResult>>(
		`/api/workspaces/${workspaceId}/cards`,
	);	

	return data.value;
};

const QUERY_KEY_GET_WORKSPACE_CARDS = 'QUERY_KEY_GET_WORKSPACE_CARDS';

function useGetWorkspaceCardsQuery(workspaceId: string) {	
	return useQuery<WorkspaceCardsResult>(
		{
			queryKey: [QUERY_KEY_GET_WORKSPACE_CARDS, workspaceId],
			queryFn: () => getWorkspaceCards(workspaceId),
		},
		queryClientForApi
	);
}

export { getWorkspaceCards, useGetWorkspaceCardsQuery, QUERY_KEY_GET_WORKSPACE_CARDS };
