/* eslint-disable indent */
import React from 'react';
import { ListItem, Avatar, HStack, Text } from '@chakra-ui/react';
import { UseComboboxGetItemPropsReturnValue, Overwrite } from 'downshift';
import { BoardCardItemResponse } from '../../features/Dashboard/types';
import { useAvatar } from '../TaskCard/hooks';
import { useChats } from '../../hooks/useChats';

interface ICommandProps
    extends Omit<
        Overwrite<
            UseComboboxGetItemPropsReturnValue,
            {
                item: string;
                index: number;
            }
        >,
        'item' | 'index'
    > {
    isHighlighted: boolean;
    data: BoardCardItemResponse;
}

export const Command = React.forwardRef<null, ICommandProps>(
    ({ isHighlighted, data, ...rest }, ref) => {
        const chat = useChats().getChatInfo(data.chatTelegramId.toString());
        const chatAvatar = useAvatar(chat?.id, chat?.accessHash);

        return (
            <ListItem
                backgroundColor={isHighlighted ? '#0000000A' : undefined}
                ref={ref}
                display="flex"
                borderRadius="4px"
                alignItems="center"
                p="9px 4px 9px 10px"
                justifyContent="space-between"
                {...rest}
            >
                <HStack spacing="8px">
                    <Avatar
                        boxSize="14px"
                        borderRadius="2px"
                        src={chatAvatar || ''}
                        name={data.cardName}
                        sx={{
                            '> div': {
                                fontSize: '8px',
                            },
                        }}
                    />
                    <Text
                        fontWeight={500}
                        maxW="100%"
                        isTruncated={true}
                        title={data.cardName}
                        lineHeight="24px"
                        color={'primary'}
                        fontSize={"13px"}
                    >
                        {data.cardName}
                    </Text>
                </HStack>
                {/* <chakra.span fontSize="13px" color="gray.35" fontWeight={400}>
					⌘1
				</chakra.span> */}
            </ListItem>
        );
    },
);
