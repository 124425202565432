import { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { CustomTag, Icon } from '../../../components';
import { getLabelInfoByType } from '../../../utils';
import { useGetWorkspaceLabels } from '../../../queries';
import { useGetWorkspaceId } from '../../../hooks';

import { useCardFilters } from './FilterSelect/useCardFilters';
import { FilterSelect, FilterSelectItem } from './FilterSelect/FilterSelect';

export const LabelsSelectPopup = () => {
    const workspaceId = useGetWorkspaceId();

    const { data, isLoading } = useGetWorkspaceLabels(workspaceId);

    const filters = useCardFilters();

    const labelIdSearchParam = filters.getFilterValue('labelId');

    const activeLabelId = labelIdSearchParam ? Number(labelIdSearchParam) : 0;

    const filteredLabelItems = useMemo(
        () =>
            data?.value?.workspaceLabels?.filter(
                (el) => el.workspaceLabelId !== activeLabelId,
            ) || [],
        [data?.value?.workspaceLabels, activeLabelId],
    );

    const selectedLabelData = useMemo(() => {
        const matchedLabel = data?.value?.workspaceLabels.find(
            (el) => el.workspaceLabelId === activeLabelId,
        );
        if (!matchedLabel) {
            return;
        }
        const labelData = getLabelInfoByType(matchedLabel.colourId);
        return {
            bgColor: labelData.bgColor,
            textColor: labelData.textColor,
            id: matchedLabel.workspaceLabelId,
            name: matchedLabel.name,
        };
    }, [activeLabelId, data?.value?.workspaceLabels]);

    return (
        <FilterSelect
            title="Label"
            items={filteredLabelItems.map((x) => {
                const labelData = getLabelInfoByType(x.colourId);
                return (
                    <FilterSelectItem
                        key={x.workspaceLabelId}
                        onClick={() =>
                            filters.addFilter('labelId', x.workspaceLabelId)
                        }
                    >
                        <CustomTag
                            label={x.name}
                            bgColor={labelData.bgColor}
                            labelColor={labelData.textColor}
                            wrapperProps={{
                                maxW: '100%',
                                textAlign: 'left',
                            }}
                        />
                    </FilterSelectItem>
                );
            })}
            isLoading={isLoading}
            selectedItem={
                selectedLabelData ? (
                    <CustomTag
                        label={selectedLabelData.name}
                        bgColor={selectedLabelData.bgColor}
                        labelColor={selectedLabelData.textColor}
                        enableCloseButton={true}
                        wrapperProps={{
                            pr: '3px',
                            maxW: '160px',
                        }}
                        closeButtonProps={{
                            'aria-label': 'clear labels filter',
                            onClick: (ev) => {
                                ev.stopPropagation();
                                filters.removeFilter('labelId');
                            },
                        }}
                        closeIconColor={selectedLabelData.textColor}
                    />
                ) : null
            }
            emptyItemsMessage={
                <Text color="secondary" textAlign="center">
                    No labels{' '}
                    {data?.value?.workspaceLabels?.length ? 'anymore' : 'yet'}
                </Text>
            }
            icon={
                <Icon name="label" height="16" width="16" color={'inherit'} />
            }
        />
    );
};
