import { useState } from "react";
import { useForm } from "react-hook-form";
import { CreateWorkspaceForm, createWorkspaceResolver } from "./schema";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { InputText } from "@components/index";
import { UploadWorkspaceAvatar } from "../../../modals/Onboarding/components";
import { useCreateWorkspaceMutation } from "@core/api";
import { createdWorkspaceIdAtom } from "../state";
import { useAtom } from "jotai";
import { OnboardingScreenProps } from "../types";
import { useGetCurrentUserWorkspacesQuery } from "@core/api";

function OnboardingCreateWorkspace({ goToNext }: OnboardingScreenProps) {
  const [_, setCreatedWorkspaceId] = useAtom(createdWorkspaceIdAtom);
	const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();
  const [imageFromFile, setImageFromFile] = useState('');
	const [imageFile, setImageFile] = useState<File | null>(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm<CreateWorkspaceForm>({ resolver: createWorkspaceResolver });

	const createWorkspaceMutation = useCreateWorkspaceMutation();

	const showImage = (file: File) => {
		const imageDataUrl = URL.createObjectURL(file);
		setImageFile(file);
		setImageFromFile(imageDataUrl);
		clearErrors('image');
	};

	const removeImage = () => {
		setImageFromFile('');
		setImageFile(null);
	};

	const onSubmit = async (data: CreateWorkspaceForm) => {
		try {		
			const formData = new FormData();
	
			formData.append('Name', data.name);
	
			if (imageFile) {
				formData.append('NewImage', imageFile);
			}
	
			const newWorkspaceId = await createWorkspaceMutation.mutateAsync(formData);
			
			setCreatedWorkspaceId(newWorkspaceId);
			await getCurrentUserWorkspacesQuery.refetch();
			goToNext();
		} catch (error) {
			console.log(error);
		}
	};

	return (
    <form onSubmit={handleSubmit(onSubmit)}>
			<Flex flexDirection={'column'} align={'center'} gap={'30px'}>
				<Box>
					<Text
						fontWeight={'600'}
						fontSize={'20px'}
						lineHeight={'24px'}
						textAlign={'center'}>
						Create your workspace
					</Text>

					<Text textAlign={'center'} mt={'16px'} color={'secondary'}>
						A shared environment where you will be able to manage
						your customer relations with your team.
					</Text>
				</Box>

				<UploadWorkspaceAvatar
					imageFromFile={imageFromFile}
					removeImage={removeImage}
					showImage={showImage}
					title="Workspace logo"
				/>

				<Box w={'100%'}>
					<Text
						fontWeight={'600'}
						fontSize={'13px'}
						color={'primary'}
					>
						Workspace name
					</Text>

					<Text 
						fontWeight={'400'}
						fontSize={'12px'}
						color={'gray.35'}
					>
						The name of your organization
					</Text>

					<InputText
						{...register('name')}
						mt={'16px'}
						placeholder={'Apple'}
						errorMsg={errors?.name?.message}
					/>
				</Box>

				<Button
					w={'65%'}
					type="submit"
					isLoading={createWorkspaceMutation.isPending}
        		>
					Continue
				</Button>
			</Flex>
		</form>
  );
}

export default OnboardingCreateWorkspace;
