import { memo, PropsWithChildren, useEffect } from 'react';
import { Path, useNavigate, useParams } from '@router';
import { useGetCurrentUserQuery, useGetCurrentUserWorkspacesQuery } from '@core/api';
import { Spinner } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '../../redux/reducers/auth.reducer';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { useLocation } from 'react-router-dom';

function Redirects({ children }: PropsWithChildren) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const path = location.pathname as Path;

	const { workspaceId } = useParams('/:workspaceId');
	
	const { isSynced } = useSelector((state: RootStore) => state.syncState);

	const getCurrentUserQuery = useGetCurrentUserQuery();

	const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();

	// Rework from effect to render?
	useEffect(() => {
		console.log('Redirects: ', { 
			path,
			workspaceId,
			isSynced,
			currentUser: getCurrentUserQuery.data, 
			currentUserWorkspaces: getCurrentUserWorkspacesQuery.data
		});

		if (getCurrentUserQuery.isSuccess && getCurrentUserWorkspacesQuery.isSuccess && isSynced) {
			// Remove it later, when deps cleared.
			dispatch(setAuthUser(getCurrentUserQuery.data))

			if (!getCurrentUserQuery.data.isOnboarded) {
				console.log('Redirects: to onboarding modal');
		
				navigate('/onboarding');
				return;
			}

			if (!getCurrentUserQuery.data.workSpace?.id) {
				console.log('Redirects: to workspace selector');

				navigate('/select-workspace');
				return;
			}

			if (workspaceId && !getCurrentUserWorkspacesQuery.data.find(({ id }) => String(id) === workspaceId)) {
				console.log('Redirects: to workspace selector');

				navigate('/select-workspace');
				return;
			}
	
			if (!workspaceId && getCurrentUserQuery.data.workSpace?.id) {
				console.log('Redirects: to current workspace');
	
				navigate('/:workspaceId', { params: { workspaceId: String(getCurrentUserQuery.data.workSpace.id) } });
				return;
			}
		}
	}, [
		getCurrentUserQuery.isSuccess, 
		getCurrentUserQuery.data,
		getCurrentUserWorkspacesQuery.isSuccess,
		getCurrentUserWorkspacesQuery.data,
		workspaceId,
		isSynced,
		path
	])

	if (getCurrentUserQuery.isError || getCurrentUserWorkspacesQuery.isError) return <>Something went wrong. Please, reload page.</>

	if (getCurrentUserQuery.isLoading || getCurrentUserWorkspacesQuery.isLoading) return <Spinner size='xl' />

	return children;
}

export default memo(Redirects);
