import React, { useMemo, useRef } from 'react';
import {
	Box,
	Flex,
	HStack,
	Text,
	useDisclosure,
	useOutsideClick,
} from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { CardLabelItem, LabelsPopup } from '../components';
import { LabelItemType } from '../../../../../types';
import { colors } from '../../../../../theme/colors';

interface LabelsSectionProps {
	cardId: number;
	workspaceId: number;
	cardLabels: LabelItemType[];
}

export const LabelsSection: React.FC<LabelsSectionProps> = ({
	cardId,
	workspaceId,
	cardLabels,
}) => {
	const { isOpen, onToggle, onClose, onOpen } = useDisclosure();

	const labelsPopupRef = useRef<HTMLDivElement | null>(null);
	const labelsBoxRef = useRef<HTMLDivElement | null>(null);
	const labelEditPopupRef = useRef<HTMLDivElement | null>(null);

	useOutsideClick({
		ref: labelsPopupRef,
		handler: event => {
			if (
				labelsBoxRef?.current?.contains(event.target as Node) ||
				labelEditPopupRef?.current?.contains(event.target as Node) ||
				labelsPopupRef?.current?.contains(event.target as Node) ||
				!isOpen
			) {
				return;
			}

			onClose();
		},
	});

	const cardLabelIds = useMemo(
		() => cardLabels?.map(el => el.workspaceLabelId) || [],
		[cardLabels],
	);

	return (
		<HStack
			p="4px 12px"
			borderRadius="4px"
			borderWidth="1px"
			borderColor="gray.20"
			align="flex-start"
			spacing={1}>
			<HStack spacing={1} pt="8px" pb="4px">
				<Icon
					name="label"
					width="16px"
					height="16px"
					color={colors.tertiary}
				/>
				<Text variant="bodyMedium" color="tertiary">
					Labels
				</Text>
			</HStack>
			<LabelsPopup
				workspaceId={workspaceId}
				cardId={cardId}
				labelEditPopupRef={labelEditPopupRef}
				ref={labelsPopupRef}
				cardLabelIds={cardLabelIds}
				isOpen={isOpen}
				onClose={onClose}
				onToggle={onToggle}>
				<Box
					ref={labelsBoxRef}
					w="full"
					h="100%"
					bg={isOpen ? 'gray.15' : 'transparent'}
					transition="0.3s background ease"
					borderRadius="4px"
					p="6px 8px"
					minH="32px"
					maxH="calc(100vh - 200px)"
					overflow="auto"
					cursor="pointer"
					onClick={onOpen}>
					<Flex flexWrap="wrap" columnGap={1} rowGap={1}>
						{cardLabels?.length ? (
							cardLabels.map(el => (
								<CardLabelItem
									key={el.workspaceLabelId}
									enableCloseButton={isOpen}
									workspaceId={workspaceId}
									cardId={cardId}
									{...el}
								/>
							))
						) : (
							<Text
								w="full"
								fontSize="12px"
								lineHeight="20px"
								color="secondary">
								No labels yet
							</Text>
						)}
					</Flex>
				</Box>
			</LabelsPopup>
		</HStack>
	);
};
