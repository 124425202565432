import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useSteps,
} from '@chakra-ui/react';

import css from './index.module.css';

import OnboardingCreateWorkspace from './create-workspace';
import OnboardingInviteTeam from './invite-team';
import OnboardingSyncChats from './sync-chats';
import OnboardingPrivacyControl from './privacy-control';
import OnboardingSelectWorkspace from './select-workspace';
import { useGetCurrentUserWorkspacesQuery } from '@core/api/queries/current-user-workspaces';

const steps_new = [
    {
        key: 'privacy-control',
        title: 'Privacy control',
        Content: OnboardingPrivacyControl,
    },
    {
        key: 'create-workspace',
        title: 'Create your workspace',
        Content: OnboardingCreateWorkspace,
    },
    {
        key: 'invite-team',
        title: 'Invite your team',
        Content: OnboardingInviteTeam,
    },
    { 
        key: 'sync-chats', 
        title: 'Chats sync', 
        Content: OnboardingSyncChats
    },
];

const steps_invited = [
    {
        key: 'privacy-control',
        title: 'Privacy control',
        Content: OnboardingPrivacyControl,
    },
    {
        key: 'select-workspace',
        title: 'Select workspace',
        Content: OnboardingSelectWorkspace,
    },
    {
        key: 'create-workspace',
        title: 'Create your workspace',
        Content: OnboardingCreateWorkspace,
    },
    {
        key: 'invite-team',
        title: 'Invite your team',
        Content: OnboardingInviteTeam,
    },
    { 
        key: 'sync-chats', 
        title: 'Chats sync', 
        Content: OnboardingSyncChats
    },
];

function OnboardingNew() {
    const { data: workspaces } = useGetCurrentUserWorkspacesQuery();
    const steps = workspaces?.length ? steps_invited : steps_new;
    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: steps.length,
    });
    const currentStep = steps[activeStep];

    const CurrentStepComponent = currentStep.Content;

    const handleCancel = () => {};

    return (
        <Modal
            isOpen={true}
            isCentered={true}
            onClose={handleCancel}
        >
            <ModalOverlay />

            <ModalContent className={css.content}>
                <ModalBody className={css.body}>
                    <CurrentStepComponent
                        key={currentStep.key}
                        goToNext={goToNext}
                        goToPrevious={goToPrevious}
                        cancel={handleCancel}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default OnboardingNew;
