import { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { CustomTag, Icon } from '../../../components';
import { useGetWorkspaceLabels, useGetWorkspaceMembers } from '../../../queries';
import { useGetWorkspaceId } from '../../../hooks';

import { useCardFilters } from './FilterSelect/useCardFilters';
import { FilterSelect, FilterSelectItem } from './FilterSelect/FilterSelect';
import { getAvatarBg } from '../../../utils';

export const ChatOwnerSelect = () => {
    const workspaceId = useGetWorkspaceId();

    const { data, isLoading } = useGetWorkspaceMembers();

    const filters = useCardFilters();

    const ownerSearchParam = filters.getFilterValue('owner');

    const activeOwnerId = ownerSearchParam ? Number(ownerSearchParam) : 0;

    const selectedOwnerData = useMemo(() => {
        const matchedOwner = data?.value?.members.find(
            (el) => el.id === activeOwnerId,
        );
        if (!matchedOwner) {
            return;
        }
        return {
            id: matchedOwner.id,
            name: matchedOwner.firstName + ' ' + matchedOwner.lastName,
            imageLink: matchedOwner.avatarThumbnailUrl,
        };
    }, [activeOwnerId, data?.value?.members]);

    return (
        <FilterSelect
            title="Owner"
            items={data?.value?.members?.map((x) => {
                return (
                    <FilterSelectItem
                        key={x.id}
                        onClick={() =>
                            filters.addFilter('owner', x.id.toString())
                        }
                    >
                        <CustomTag
                            label={x.firstName + ' ' + x.lastName}
                            bgColor='transparent'
                            labelColor='inherit'
                            wrapperProps={{
                                maxW: '100%',
                                textAlign: 'left',
                            }}
                        />
                    </FilterSelectItem>
                );
            }) ?? []}
            isLoading={isLoading}
            selectedItem={
                selectedOwnerData ? (
                    <CustomTag
                        label={selectedOwnerData.name}
                        bgColor='transparent'
                        labelColor='inherit'
                        enableCloseButton={true}
                        wrapperProps={{
                            pr: '3px',
                            maxW: '160px',
                        }}
                        closeButtonProps={{
                            'aria-label': 'clear owner filter',
                            onClick: (ev) => {
                                ev.stopPropagation();
                                filters.removeFilter('owner');
                            },
                        }}
                    />
                ) : null
            }
            emptyItemsMessage={
                <Text color="secondary" textAlign="center">
                    No owners{' '}
                    {data?.value?.members?.length ? 'anymore' : 'yet'}
                </Text>
            }
            icon={
                <Icon name="user-circle" height="16" width="16" color={'inherit'} />
            }
        />
    );
};
