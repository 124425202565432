import React, { useRef } from 'react';
import {
	Box,
	Flex,
	HStack,
	Text,
} from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';

interface LastActivitySectionProps {
	chat: any;
}

export const LastActivitySection: React.FC<LastActivitySectionProps> = ({
	chat,
}) => {
	const labelsBoxRef = useRef<HTMLDivElement | null>(null);
	
	return (
		<HStack
			p="4px 12px"
			borderRadius="4px"
			borderWidth="1px"
			borderColor="gray.20"
			align="flex-start"
			whiteSpace="nowrap"
			spacing={1}>
			<HStack spacing={1} pt="8px" pb="4px">
				<Icon
					name="calendar"
					width="16px"
					height="16px"
					color={colors.tertiary}
				/>
				<Text variant="bodyMedium" color="tertiary">
					Last Activity
				</Text>
			</HStack>
				<Box
					ref={labelsBoxRef}
					w="full"
					h="100%"
					bg='transparent'
					borderRadius="4px"
					p="6px 8px"
					minH="32px"
					maxH="calc(100vh - 200px)"
					overflow="auto"
					cursor="pointer">
					<Flex flexWrap="wrap" columnGap={1} rowGap={1}>
							<Text
								w="full"
								fontSize="12px"
								lineHeight="20px"
								color="secondary">
							{chat?.lastMessage.date ? new Date(chat?.lastMessage.date * 1000).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'No activity yet'}
						</Text>
					</Flex>
				</Box>
		</HStack>
	);
};
