import { useEffect } from 'react';
import { TelegramWindowContextType, useTelegram } from '../../services';
import { useChats } from '../useChats';
import { ApiChat, ApiMessage } from '../../../../telegram-tt/src/api/types';
import { trpc } from '@core/api/trpc';
import { useQuery } from '@tanstack/react-query';

export const useChatsHistory = () => {
    const tg = useTelegram();
    const chats = useChats();

    const historyQuery = useQuery({
        queryKey: ['chats-history'],
        queryFn: () => loadChatsHistory(tg, Object.values(chats.data.chats)),
        enabled: !!tg && !!chats.data.chats,
        select(data) {
            // store data as a key-value pair
            return data.reduce(
                (acc: Record<string, string>, item: any) => {
                    acc[item.chatId] = item.historyText;
                    return acc;
                },
                {} as Record<string, string>,
            );
        },
    });

    return historyQuery;
};

export const useAiChats = () => {
    const tg = useTelegram();
    const chats = useChats();
    const { mutate: analyzeChat } = trpc.aiChats.analyzeChat.useMutation();
    const historyQuery = useChatsHistory();

    return historyQuery;
};

let historyPromise: Promise<any> | null = null;

const loadChatsHistory = async (
    tg?: TelegramWindowContextType,
    chats?: ApiChat[],
) => {
    if (!tg || !chats) return;

    if (historyPromise) return historyPromise;

    historyPromise = Promise.all(
        chats.map(async (chat) => {
            const messages = await tg.methods.proxy.fetchMessages({
                chat,
                limit: 100,
                threadId: -1,
            });

            const historyText = messages?.messages.reduce(
                (acc, message, idx) => {
                    const summaryEmoji = getMessageSummaryEmoji(message);
                    const sender = messages?.users.find(
                        (user) => user?.id === message?.senderId,
                    );

                    const senderName =
                        sender?.firstName ??
                        sender?.usernames?.[0]?.username ??
                        messages.chats[idx]?.title;

                    const messageText = message?.content?.text?.text || '';
                    const messageDate = message?.date
                        ? new Date(message?.date * 1000).toLocaleString()
                        : '';

                    return `${acc}
---
${messageDate}: ${senderName}
${messageText}
${summaryEmoji}`;
                },
                '',
            );

            return {
                chatId: chat.id,
                historyText,
            };
        }),
    ).finally(() => {
        historyPromise = null;
    });

    console.log('AI: MESSAGES', historyPromise);

    return historyPromise;
};

function getMessageSummaryEmoji(message?: ApiMessage) {
    if (!message) return;

    const { photo, video, audio, voice, document, sticker, pollId, paidMedia } =
        message.content;

    if (message.groupedId || photo || paidMedia) {
        return '🖼';
    }

    if (video) {
        return '📹';
    }

    if (sticker) {
        return sticker.emoji;
    }

    if (audio) {
        return '🎧';
    }

    if (voice) {
        return '🎤';
    }

    if (document) {
        return '📎';
    }

    if (pollId) {
        return '📊';
    }

    return undefined;
}
