import { useEffect } from 'react';
import {
	Box,
	Flex,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { TabWithIcon } from '../../components';
import { TABS } from './constants';
import { TasksPanelTemplateTabs } from './tabs';
import { QueryKeys } from '../../constants';
import { useClearTasksData } from './hooks';
import { useUserAvatars, useGetWorkspaceId } from '../../hooks';
import { ApiResponse } from '../../types';
import { DefaultPageHeader } from '../../widgets';

const TasksPage = () => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	const { clearTasksQueryData } = useClearTasksData();

	const teamTelegramIds = queryClient.getQueryData<
		ApiResponse<{ telegramUserIds: number[] }>
	>([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

	useUserAvatars(teamTelegramIds?.value?.telegramUserIds);

	useEffect(() => {
		return () => {
			if (workspaceId) {
				clearTasksQueryData(
					QueryKeys.GET_ALL_CURRENT_USER_TASKS,
					workspaceId,
				);
				clearTasksQueryData(
					QueryKeys.GET_ALL_TASKS_OTHER_USERS,
					workspaceId,
				);
			}
		};
	}, [workspaceId]);

	return (
		<Flex 
			as='section' 
			id='tasks-layout'
			w="100%" 
			h='100%'
			flexDirection={"column"}
		>
			<DefaultPageHeader title="Tasks" iconName="checkbox" />

			<Box
				borderWidth="1px"
				borderColor="gray.20"
				borderRadius="8px"
				bg="white"
				height="100%"
				overflowX="auto"
				overflowY="hidden"
			>
				<Tabs isLazy={true} variant="unstyled" height="100%">
					<TabList
						px={2}
						borderBottomWidth="1px"
						borderBottomColor="gray.15">
						{TABS.map((icon, index) => (
							<TabWithIcon
								key={index}
								label={icon.label}
								iconName={icon.iconName}
							/>
						))}
					</TabList>
					<TabIndicator
						mt="-1.5px"
						height="1px"
						bg="primary"
						borderRadius="1px"
					/>
					<TabPanels height="100%" overflowY="auto">
						<TabPanel p={6}>
							<TasksPanelTemplateTabs key={1} filterId={1} />
						</TabPanel>
						<TabPanel p={6}>
							<TasksPanelTemplateTabs key={3} filterId={3} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Flex>
	);
};

export default TasksPage;
