import {  Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import css from './index.module.css'
import Sync from './Sync';
import { useModals } from '@router';

const SyncSettingsNew = () => {
    const modals = useModals();

    const handleClose = () => {
        modals.close();
    }

    return (
        <Modal
            isOpen={true}
            isCentered={true}
            onClose={handleClose}
        >
            <ModalOverlay />

            <ModalContent className={css.content}>

                <ModalBody className={css.body}>
                    <Sync />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SyncSettingsNew;
