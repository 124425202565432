import React, { useState } from 'react';
import {
    Box,
    Text,
    VStack,
    Badge,
    Flex,
    Tooltip,
    IconButton,
    useToast,
    Button,
} from '@chakra-ui/react';
import { DeleteIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { trpc } from '@core/api/trpc';

interface ActionItemsProps {
    cardId: number;
    workspaceId: number;
}

export const ActionItems: React.FC<ActionItemsProps> = ({
    cardId,
    workspaceId,
}) => {
    const toast = useToast();
    const trpcUtils = trpc.useUtils();
    const [deletingId, setDeletingId] = useState<number | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const { data: actions, isLoading } = trpc.aiChats.getActions.useQuery({
        cardId,
        workspaceId: workspaceId,
    });

    const { mutate: deleteAction } = trpc.aiChats.deleteAction.useMutation({
        onMutate: (variables) => {
            setDeletingId(variables.actionId);
        },
        onSuccess: () => {
            setDeletingId(null);
            trpcUtils.aiChats.getActions.invalidate({ cardId, workspaceId });
            toast({
                title: 'Action item removed',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        },
        onError: (error) => {
            setDeletingId(null);
            toast({
                title: 'Failed to remove action item',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (isLoading || !actions?.length) {
        return null;
    }

    const getPriorityColor = (priority: string) => {
        switch (priority.toLowerCase()) {
            case 'high':
                return 'red';
            case 'medium':
                return 'yellow';
            case 'low':
                return 'green';
            default:
                return 'gray';
        }
    };

    const getTypeIcon = (type: string) => {
        switch (type.toLowerCase()) {
            case 'task':
                return '📋';
            case 'reminder':
                return '⏰';
            case 'follow_up':
                return '📬';
            case 'suggestion':
                return '💡';
            default:
                return '❓';
        }
    };

    const handleDelete = (actionId: number) => {
        deleteAction({ actionId });
    };

    const displayedActions = isExpanded ? actions : actions.slice(0, 3);
    const hasMore = actions.length > 3;

    return (
        <Box borderBottom="1px" borderColor="gray.100">
            <Flex
                justify="space-between"
                align="center"
                py={1}
                px={2}
                borderBottom="1px"
                borderColor="gray.100"
                // bg="gray.50"
            >
                <Flex align="center" gap={2}>
                    <Text fontSize="xs" color="gray.600" fontWeight="medium">
                        AI Actions
                    </Text>
                    <Badge fontSize="2xs" variant="subtle" colorScheme="blue">
                        {actions.length}
                    </Badge>
                </Flex>
                {hasMore && (
                    <Button
                        size="xs"
                        variant="ghost"
                        height="16px"
                        minW="auto"
                        px={1}
                        rightIcon={
                            isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />
                        }
                        onClick={() => setIsExpanded(!isExpanded)}
                        fontSize="xs"
                        color="gray.600"
                    >
                        {isExpanded ? 'Less' : `${actions.length}`}
                    </Button>
                )}
            </Flex>
            <VStack
                align="stretch"
                spacing={0}
                divider={<Box borderBottom="1px" borderColor="gray.50" />}
            >
                {displayedActions.map((item) => (
                    <Box
                        key={item.id}
                        py={1}
                        px={2}
                        opacity={deletingId === item.id ? 0.6 : 1}
                        transition="opacity 0.2s"
                    >
                        <Flex gap={1} align="center">
                            <Text fontSize="xs" color="gray.500">
                                {getTypeIcon(item.action.type)}
                            </Text>
                            <Badge
                                variant="subtle"
                                fontSize="2xs"
                                colorScheme={getPriorityColor(
                                    item.action.priority,
                                )}
                            >
                                {item.action.priority}
                            </Badge>
                            {item.action.due_date && (
                                <Tooltip
                                    label={new Date(
                                        item.action.due_date,
                                    ).toLocaleString()}
                                >
                                    <Badge
                                        variant="subtle"
                                        fontSize="2xs"
                                        colorScheme="blue"
                                    >
                                        d
                                    </Badge>
                                </Tooltip>
                            )}
                            {item.action.owner && (
                                <Badge
                                    variant="subtle"
                                    fontSize="2xs"
                                    colorScheme="purple"
                                >
                                    {item.action.owner}
                                </Badge>
                            )}
                            <Text
                                fontSize="xs"
                                flex={1}
                                noOfLines={1}
                                color="gray.700"
                            >
                                {item.action.description}
                            </Text>
                            <IconButton
                                aria-label="Delete action"
                                icon={<DeleteIcon />}
                                size="xs"
                                variant="ghost"
                                colorScheme="red"
                                onClick={() => handleDelete(item.id)}
                                isLoading={deletingId === item.id}
                                isDisabled={deletingId !== null}
                                width="16px"
                                height="16px"
                                minW="auto"
                                fontSize="8px"
                            />
                        </Flex>
                    </Box>
                ))}
            </VStack>
        </Box>
    );
};
