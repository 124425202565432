import { createContext, useContext } from 'react';
import {
    Box,
    Button,
    HStack,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import {
    Loader,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../../../../components';

import styles from './FilterSelect.module.css';

const Ctx = createContext({ onClose: () => {} });

type FilterSelectProps = {
    items: React.ReactNode[];
    selectedItem: React.ReactNode;
    icon: React.ReactNode;
    title: string;
    isLoading: boolean;
    emptyItemsMessage?: React.ReactNode;
};
export const FilterSelect = (props: FilterSelectProps) => {
    const { isOpen, onToggle, onClose } = useDisclosure();

    const isActiveState = isOpen || !!props.selectedItem;

    return (
        <Ctx.Provider value={{ onClose }}>
            <Popover open={isOpen} onOpenChange={onToggle}>
                <PopoverTrigger asChild={true}>
                    <HStack
                        spacing={1}
                        p="5px 8px"
                        align="center"
                        borderRadius="4px"
                        cursor="pointer"
                        minW="fit-content"
                        bg={isActiveState ? 'gray.15' : 'transparent'}
                        _hover={{
                            bg: isActiveState ? 'gray.15' : 'gray.20',
                        }}
                        transition="0.2s background ease"
                        color={isActiveState ? 'primary' : 'secondary'}
                    >
                        <Box boxSize="16px">{props.icon}</Box>
                        <Text
                            color={'inherit'}
                            variant="bodyLarge"
                            fontWeight={500}
                        >
                            {props.title}
                        </Text>
                        {props.selectedItem}
                    </HStack>
                </PopoverTrigger>

                <PopoverContent
                    sideOffset={5}
                    align="start"
                    className={styles.LabelsSelectPopoverContent}
                >
                    <Box maxH="300px" p="4px" w="full" overflow="clip">
                        {props.isLoading ? (
                            <Loader
                                centerHeight="100px"
                                spinnerSize="md"
                                spinnerProps={{
                                    thickness: '3px',
                                }}
                            />
                        ) : props.items?.length ? (
                            <VStack
                                align="flex-start"
                                spacing="2px"
                                h="100%"
                                maxH="280px"
                                overflowY="auto"
                                sx={{
                                    scrollbarWidth: 'thin',
                                }}
                            >
                                {props.items}
                            </VStack>
                        ) : (
                            <VStack h="100px" justify="center">
                                {props.emptyItemsMessage}
                            </VStack>
                        )}
                    </Box>
                </PopoverContent>
            </Popover>
        </Ctx.Provider>
    );
};

type FilterSelectItemProps = {
    onClick: () => void;
    isSelected?: boolean;
};
export const FilterSelectItem: React.FCC<FilterSelectItemProps> = (props) => {
    const ctx = useContext(Ctx);
    return (
        <Button
            variant="unstyled"
            display="flex"
            h="32px"
            maxW="100%"
            w="full"
            p="6px 4px"
            borderRadius="4px"
            justifyContent="flex-start"
            whiteSpace="normal"
            onClick={() => {
                props.onClick();
                ctx.onClose();
            }}
            _focusVisible={{
                boxShadow: 'unset',
            }}
            _hover={{
                bg: 'transparentLight.10',
                textAlign: 'left',
            }}
        >
            {props.children}
        </Button>
    );
};
