import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import { TG_CONFIG } from '../../tg-config';
import { useTelegram } from '../../services';
import {
    useThrottleCallback,
    useGetMatchedCardId,
    useGetCardById,
} from '../../hooks';
import { useLogout } from '../../queries';
import { RootStore } from '../../redux/createStore';
import {
    CardDefaultStatusEnum,
    TGStateAuthenticatedUser,
    TGStateUnauthenticatedUser,
    TGSyncState,
} from '../../types';
import {
    setShowTelegramModal,
    setTelegramConnected,
} from '../../redux/reducers/login-flow.reducer';
import { setSyncState } from '../../redux/reducers/sync-state.reducer';
import { colors } from '../../theme/colors';
import { ErrorPlaceholder } from './ErrorPlaceholder';
import { limitedAccessCardsBoxStyles } from './constants';
import * as consts from '../../App.const';
import { IframeLockWrapper } from './IframeLockWrapper';

export const TelegramHiddenFrame = () => {
    const tg = useTelegram();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const cardId = useGetMatchedCardId();
    const { mutateAsync: logoutMutation } = useLogout();
    const { token, user } = useSelector((state: RootStore) => state.auth);
    const { showTelegramLoginModal } = useSelector((state: RootStore) => state.loginFlow);

    const isChat = pathname.includes('/chat') && !!user.workSpace;
    const matchedCard = useGetCardById(cardId);
    const isTelegramSetting = pathname.includes('/telegram-setting') && !!user.workSpace;
    
    const isTeamChat = useMemo(
        () => user?.workSpace?.teamChat?.cardId === cardId,
        [user, cardId],
    );

    const isCardArchived = useMemo(() => {
        if (isTeamChat) return false;
        if (!matchedCard) return true;
        if (!isChat) return false;

        return matchedCard.status?.typeId === CardDefaultStatusEnum.ARCHIVE;
    }, [isChat, matchedCard, isTeamChat]);

    const { accessToken, refreshToken } = token;

    const [statusNotSucceeded, setStatusNotSucceeded] = useState(false);
    const [statusConnected, setStatusConnected] = useState(false);

    const statusCheckCount = useRef(0);

    const handleAuthStateChange = async (
        state: TGStateUnauthenticatedUser | TGStateAuthenticatedUser,
    ) => {
        if (!state.authed && accessToken && refreshToken) {
            await logoutMutation({
                accessToken,
                refreshToken,
            });

            return;
        }

        if (state.authed && state.userId && showTelegramLoginModal) {
            dispatch(setShowTelegramModal(false));
            dispatch(
                setTelegramConnected({
                    isConnected: true,
                    userId: state.userId,
                }),
            );
        }
    };

    const throttledCallback = useThrottleCallback(handleAuthStateChange, 1);

    useEffect(() => {
        const unsubscribe = tg?.events.subscribe(
            'authStateChanged',
            throttledCallback,
        );

        console.log('authStateChanged-subscribe', unsubscribe);

        return unsubscribe;
    }, [tg?.events, throttledCallback]);

    useEffect(() => {
        const unsubscribe = tg?.events.subscribe(
            'syncStateChanged',
            (state: TGSyncState) => {
                console.log('syncStateChanged', { state });
                
                dispatch(setSyncState(state.isSynced));
            },
        );
        console.log('authStateChanged-subscribed', unsubscribe);


        return unsubscribe;
    }, [tg?.events]);

    useEffect(() => {
        const unsubscribe = tg?.events.subscribe('loggedOut', async () => {
            console.log('loggedOut');

            if (accessToken && refreshToken) {
                await logoutMutation({
                    accessToken,
                    refreshToken,
                });
            }
        });

        console.log('loggedOut-subscribed', unsubscribe);

        return unsubscribe;
    }, [tg?.events, accessToken, refreshToken]);

    useInterval(
        () => {
            statusCheckCount.current = statusCheckCount.current + 1;

            // @ts-expect-error: Do not rewrite to async
            tg?.status.proxy.check().then((res) => {
                if (res) {
                    setStatusConnected(true);
                }
            });

            if (statusCheckCount.current >= 10) {
                setStatusNotSucceeded(true);
            }
        },
        !statusNotSucceeded && !statusConnected ? 1000 : null,
    );

    const onReload = () => {
        setStatusNotSucceeded(false);
        statusCheckCount.current = 0;
    };

    const getWidth = useCallback(() => {
        switch (true) {
            case showTelegramLoginModal:
                return '400px';
            case !!isChat:
                return '100%';
            case !!isTelegramSetting:
                return '100%';

            default:
                return 0;
        }
    }, [isChat, showTelegramLoginModal, isTelegramSetting]);

    const width = getWidth();

    const iframeUrl = buildTelegramIframeUrl(
        TG_CONFIG.TG_IFRAME_URL,
        consts.TEST_BRANCH,
    )    

    return (
        <Box
            id="tg-iframe-holder"
            zIndex={10}
            flexShrink={showTelegramLoginModal ? 0 : 1}
            width={width}
            visibility={isChat || showTelegramLoginModal || isTelegramSetting ? 'initial' : 'hidden'}
            height={showTelegramLoginModal ? '736px' : '100%'}
            position="relative"
            sx={{
                ...(isChat && isCardArchived
                    ? limitedAccessCardsBoxStyles
                    : {}),
            }}
        >
            {statusNotSucceeded ? (
                <ErrorPlaceholder onReload={onReload} />
            ) : (
                <IframeLockWrapper iframeOpened={width !== 0}>
                    <iframe
                        id="telegram-iframe"
                        height="100%"
                        width="100%"
                        allow="clipboard-read; clipboard-write; encrypted-media; gyroscope;"
                        style={{
                            borderRadius: '8px',
                            border: `1px solid ${colors.gray[20]}`,
                        }}
                        src={iframeUrl}
                        referrerpolicy="no-referrer"
                    ></iframe>
                </IframeLockWrapper>
            )}
        </Box>
    );
};

const buildTelegramIframeUrl = (iframeUrl: string, branch?: string) => {
    const url = new URL(iframeUrl);
    if (branch) {
        url.searchParams.set('branch', branch);
    }
    return url.toString();
};

const IDENTIFICATED_USER_KEY = 'dise_june_identificated_user_id';
