import React from 'react';
import { Text, HStack, Button, Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Icon } from '../../../components';
import { setSelectTeamChatModalOpenState } from '../../../redux/reducers/workspace.reducer';
import { MiniTeamChatCard } from './MiniTeamChatCard';
import { SectionTitle } from './SectionTitle';
import { SectionDescription } from './SectionDescription';

export const TeamChatSelector: React.FC = () => {
    const dispatch = useDispatch();

    return (
        <Box>
            <SectionTitle>Team Chat</SectionTitle>
            <SectionDescription mt="12px" mb="16px">
                The pinned chat with your team
            </SectionDescription>
            <HStack spacing="16px">
                <MiniTeamChatCard />
                <Button
                    variant="outline"
                    onClick={() =>
                        dispatch(setSelectTeamChatModalOpenState(true))
                    }
                    leftIcon={
                        <Icon name="refresh" height="16px" width="16px" />
                    }
                >
                    Select a new chat
                </Button>
            </HStack>
        </Box>
    );
};
