import { memo } from 'react';
import { Box } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { TaskCardSolo } from '../../../components';
import { BoardCardItemResponse } from '../types';
import { useIsMutating } from '@tanstack/react-query';
import { QueryKeys } from '../../../constants';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { useChats, type ChatItem } from '../../../hooks/useChats';

interface DraggableTaskCardProps {
    task: BoardCardItemResponse;
    index: number;
    columnStatusId: number;
    tabIndex: number;
    onRedirect: (obj: {
        cardData: BoardCardItemResponse;
        statusId?: number;
        filterId?: number;
    }) => void;
    chat?: ChatItem;
    chats: ReturnType<typeof useChats>;
}

const DraggableTaskCard = ({
    task,
    index,
    columnStatusId,
    tabIndex,
    onRedirect,
    chat,
    chats,
}: DraggableTaskCardProps) => {
    const workspaceId = useGetMatchedWorkspaceId();

    const isWorkspaceCardStatusMutating = useIsMutating({
        mutationKey: [QueryKeys.UPDATE_WORKSPACE_CARD_STATUS],
        predicate: (mutation) => {
            if (
                mutation.state.variables.workspaceId === workspaceId &&
                mutation.state.variables.cardId === task.cardId
            )
                return true;

            return false;
        },
    });

    return (
        <Draggable
            key={task.cardId}
            draggableId={`${task.cardId}`}
            index={index}
            isDragDisabled={!!isWorkspaceCardStatusMutating}
        >
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    mb={1.5}
                    style={{
                        ...provided.draggableProps.style,
                        userSelect: 'none',
                        transition: snapshot.isDragging
                            ? 'none'
                            : 'all 0.2s ease-in-out',
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <TaskCardSolo
                        isDragging={
                            snapshot.isDragging ||
                            !!isWorkspaceCardStatusMutating
                        }
                        cardName={task.cardName}
                        chatTelegramId={task.chatTelegramId}
                        chat={chat}
                        chats={chats}
                        onRedirect={() =>
                            onRedirect({
                                cardData: task,
                                statusId: columnStatusId,
                                filterId: tabIndex + 1,
                            })
                        }
                        companyChatData={task.message}
                        currentUserTagged={task.currentUserTagged}
                        trackedLastTelegramMessageUnixTime={
                            task.trackedLastTelegramMessageUnixTime
                        }
                        cardId={task.cardId}
                        cardStatusId={task.status.typeId}
                        labels={task.labels}
                    />
                </Box>
            )}
        </Draggable>
    );
};

export default memo(DraggableTaskCard);
