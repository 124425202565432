import {  Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Button, Center } from '@chakra-ui/react';

import Selector from './selector';
import { OnboardingScreenProps } from '../types';
import css from './index.module.css'

export default function SelectWorkspaceNew({ goToNext }: OnboardingScreenProps) {
    return (
        <Modal
            isOpen={true}
            isCentered={true}
            onClose={() => {}}
        >
            <ModalOverlay />

            <ModalContent className={css.content}>
                <ModalHeader className={css.header}>
                    Select a workspace
                </ModalHeader>

                <ModalBody className={css.body}>
                    <Selector />
                </ModalBody>
                <Center>
                <Button
                    variant='outline'
                    boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
                    onClick={goToNext}
                >
                    Create a workspace
                </Button>
            </Center>
            </ModalContent>
        </Modal>
    );
}
