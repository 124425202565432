// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client';

export type Path =
    | `/`
    | `/:workspaceId`
    | `/:workspaceId/chat/:chatId`
    | `/:workspaceId/notifications`
    | `/:workspaceId/settings`
    | `/:workspaceId/settings/members`
    | `/:workspaceId/settings/pipeline`
    | `/:workspaceId/tasks`
    | `/:workspaceId/telegram-setting`
    | `/login`
    | `/onboarding`
    | `/select-workspace`;

export type Params = {
    '/:workspaceId': { workspaceId: string };
    '/:workspaceId/chat/:chatId': { workspaceId: string; chatId: string };
    '/:workspaceId/notifications': { workspaceId: string };
    '/:workspaceId/settings': { workspaceId: string };
    '/:workspaceId/settings/members': { workspaceId: string };
    '/:workspaceId/settings/pipeline': { workspaceId: string };
    '/:workspaceId/tasks': { workspaceId: string };
    '/:workspaceId/telegram-setting': { workspaceId: string };
};

export type ModalPath =
    | `/batch-sending`
    | `/create-workspace`
    | `/sync-settings`;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<
    Path,
    Params,
    ModalPath
>();
export const { redirect } = utils<Path, Params>();
