import { useQuery } from '@tanstack/react-query';
import { LegacyApiResponse } from '../axios';
import { queryClientForApi } from '../query-client';
import axios from 'axios';

export type Folder = {
	telegramChatsIds: number[];
	telegramFolderId: number;
};

export type CurrentUserWorkspaceFoldersResult = {
    folders: Folder[];
};

const getCurrentUserWorkspaceFolders = async (workspaceId: string) => {
	const { data } = await axios.get<LegacyApiResponse<CurrentUserWorkspaceFoldersResult>>(
		`/api/users/folders`,
        {
            params: {
                workspaceId,
            }
        }
	);	

	return data.value;
};

const QUERY_KEY_GET_CURRENT_USER_WORKSPACE_FOLDERS = 'QUERY_KEY_GET_CURRENT_USER_WORKSPACE_FOLDERS';

function useGetCurrentUserWorkspaceFoldersQuery(workspaceId: string) {	
	return useQuery<CurrentUserWorkspaceFoldersResult>(
		{
			queryKey: [QUERY_KEY_GET_CURRENT_USER_WORKSPACE_FOLDERS, workspaceId],
			queryFn: () => getCurrentUserWorkspaceFolders(workspaceId),
		},
		queryClientForApi
	);
}

export { getCurrentUserWorkspaceFolders, useGetCurrentUserWorkspaceFoldersQuery, QUERY_KEY_GET_CURRENT_USER_WORKSPACE_FOLDERS };