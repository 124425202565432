import { useEffect, useMemo, useState } from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Loader } from '../../components';
import { getBoardTabs } from './constants';
import { BoardTab, LabelsSelectPopup } from './components';
import DashboardPanelTemplateTabs from './DashboardPanelTemplate.tabs';
import { useGetBoardCards } from './queries';
import { RootStore } from '../../redux/createStore';
import { useGetWorkspaceId } from '../../hooks';
import { BoardFilterValue } from './types';
import { ApiResponse } from '../../types';
import { QueryKeys } from '../../constants';
import {
    getUnreadCards,
    getUnansweredCards,
    getMentionedCards,
    getActiveTasksCards,
    getTeamActivityCards,
} from '../../utils';
import { DefaultPageHeader } from '../../widgets';
import { useChats } from '../../hooks/useChats';
import { IconFilter } from '@tabler/icons-react';
import { useCardFilters } from './components/FilterSelect/useCardFilters';
import { LastActivitySelect } from './components/LastActivitySelect';
import { ChatOwnerSelect } from './components/ChatOwnerSelect';
import { useAutoSync } from '../../hooks/useAutoSync';
import { useAiChats } from '../../hooks/ai/useAiChats';

const DashboardPage = () => {
    useAiChats();
    useAutoSync();
    const queryClient = useQueryClient();

    const workspaceId = useGetWorkspaceId();

    const isArchiveVisible = useSelector(
        (state: RootStore) => state.auth.user.workSpace?.isArchiveVisible,
    );

    const chats = useChats();

    const filters = useCardFilters();
    const [filtersVisible, setFiltersVisible] = useState(() =>
        filters.hasActiveFilters(),
    );

    const { data, isLoading } = useGetBoardCards();

    const teamTelegramIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    const [tabIndex, setTabIndex] = useState(BoardFilterValue.All);

    const renderTabs = useMemo(() => {
        const cards = (data || []).filter((card) =>
            filters.applyFilters(card, chats),
        );

        return getBoardTabs({
            unread:
                getUnreadCards({
                    cards,
                    chats,
                }).length || 0,

            unanswered:
                getUnansweredCards({
                    cards,
                    chats,
                    teamTelegramIds:
                        teamTelegramIds?.value?.telegramUserIds || [],
                }).length || 0,
            activeTasks:
                getActiveTasksCards({
                    cards,
                }).length || 0,
            teamActivity:
                getTeamActivityCards({
                    cards,
                }).length || 0,

            mentions:
                getMentionedCards({
                    cards,
                }).length || 0,
        }).map((tab, index) => (
            <BoardTab
                key={tab.id}
                isActive={tab.filterValue === tabIndex}
                onClick={() => setTabIndex(index)}
                {...tab}
            />
        ));
    }, [tabIndex, data, chats, teamTelegramIds?.value?.telegramUserIds]);

    return (
        <Flex
            as="section"
            id="dashboard-layout"
            w="100%"
            h="100%"
            flexDir="column"
            pr={'0.5rem'}
        >
            <DefaultPageHeader
                title="Main Pipeline"
                iconName="users"
                isSyncSettingsEnabled={true}
                isInviteMembersEnabled={true}
            />

            {isLoading ? (
                <Loader centerHeight="100%" />
            ) : (
                <Flex
                    as="section"
                    flexDir="column"
                    borderWidth="1px"
                    borderColor="gray.20"
                    borderRadius="8px"
                    overflow="hidden"
                    bg="white"
                    h="100%"
                >
                    <Flex
                        as="header"
                        flexShrink={0}
                        columnGap={4}
                        p={2}
                        overflowX="auto"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                        alignItems={'center'}
                    >
                        {renderTabs}

                        <Flex flexGrow={1} justifyContent={'flex-end'}>
                            <OpenFiltersButton
                                opened={filtersVisible}
                                onClick={() => setFiltersVisible((p) => !p)}
                            />
                        </Flex>
                    </Flex>
                    {filtersVisible && <FiltersPanel />}

                    <DashboardPanelTemplateTabs
                        tabIndex={tabIndex}
                        data={data || []}
                        workspaceId={workspaceId}
                        chats={chats}
                        isArchiveVisible={isArchiveVisible}
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default DashboardPage;

type OpenFiltersButton = {
    opened: boolean;
    onClick: () => void;
};
const OpenFiltersButton = (props: OpenFiltersButton) => {
    return (
        <HStack
            borderRadius="4px"
            gap={'4px'}
            p={'4px'}
            px={'8px'}
            w={'max-content'}
            onClick={props.onClick}
            cursor="pointer"
            spacing={2}
            bg={props.opened ? 'gray.20' : 'transparent'}
            _hover={{
                bg: 'gray.20',
            }}
            transition="0.2s background ease"
        >
            <IconFilter color="#666" size={'14px'} />
            <Text color={'gray.50'} fontSize={'13px'} fontWeight={400}>
                Filter
            </Text>
        </HStack>
    );
};

type FiltersPanelProps = {};
const FiltersPanel = (props: FiltersPanelProps) => {
    return (
        <Flex
            p={'8px'}
            bg={'white'}
            gap={'16px'}
            borderBottomWidth="1px"
            borderBottomColor="gray.15"
        >
            <LabelsSelectPopup />
            <LastActivitySelect />
            <ChatOwnerSelect />
        </Flex>
    );
};
