import React, { useRef } from 'react';
import {
	Box,
	Flex,
	HStack,
	Text,
} from '@chakra-ui/react';
import { Icon, LabelWithImage } from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { getAvatarBg } from '../../../../../utils';

interface OwnerSectionProps {
	cardOwnerQuery: any;
}

export const OwnerSection: React.FC<OwnerSectionProps> = ({
	cardOwnerQuery
}) => {
	const labelsBoxRef = useRef<HTMLDivElement | null>(null);

	return (
		<HStack
			p="4px 12px"
			borderRadius="4px"
			borderWidth="1px"
			borderColor="gray.20"
			align="flex-start"
			spacing={1}>
			<HStack spacing={1} pt="8px" pb="4px">
				<Icon
					name="user-circle"
					width="16px"
					height="16px"
					color={colors.tertiary}
				/>
				<Text variant="bodyMedium" color="tertiary">
					Owner
				</Text>
			</HStack>
				<Box
					ref={labelsBoxRef}
					w="full"
					h="100%"
					bg='transparent'
					borderRadius="4px"
					p="6px 8px"
					minH="32px"
					maxH="calc(100vh - 200px)"
					overflow="auto"
					cursor="pointer">
					<Flex flexWrap="wrap" columnGap={1} rowGap={1}>
						{cardOwnerQuery.data ?
							<LabelWithImage
								label={`${cardOwnerQuery.data.user.firstName} ${cardOwnerQuery.data.user.lastName || ''}`}
								imageLink={cardOwnerQuery.data.user.thumbnail || ''}
								includeAvatar={true}
								avatarProps={{
										bg: getAvatarBg(cardOwnerQuery.data.user.telegramUserId || 0),
								}}
							/> : (
							<Text
								w="full"
								fontSize="12px"
								lineHeight="20px"
								color="tertiary"
								textAlign="center">
								No owner yet
							</Text>
						)}
					</Flex>
				</Box>
		</HStack>
	);
};
