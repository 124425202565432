import { createTRPCReact } from '@trpc/react-query';
import type { AppRouter } from '../../../functions/trpc/routes';
import { httpBatchLink } from '@trpc/client';
import { store } from '../../redux/createStore';
import superjson from 'superjson';

export const trpc = createTRPCReact<AppRouter>();

function getBaseUrl() {
    if (import.meta.env.VITE_TRPC_API_URL)
        return import.meta.env.VITE_TRPC_API_URL;

    if (import.meta.env.VITE_BASE_URL)
        return `${import.meta.env.VITE_BASE_URL}`;
    return `http://localhost:${import.meta.env.PORT ?? 8787}`;
}

export const trpcClient = trpc.createClient({
    // transformer: superjson,
    links: [
        httpBatchLink({
            transformer: superjson,
            url: getBaseUrl() + 'api/trpc',
            // You can pass any HTTP headers you wish here
            async headers() {
                const token = store.getState().auth.token.accessToken;
                return {
                    authorization: token,
                };
            },
        }),
    ],
});
