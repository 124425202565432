import { useState, useRef, useEffect, memo } from 'react';
import { Box, VStack, BoxProps, Skeleton } from '@chakra-ui/react';
import {
    CardBody,
    CardFooter,
    CardHeader,
    CardLabels,
    LimitedAccessView,
} from './components';
import { InternalChatMessagePreview } from '../../features/Dashboard/types';
import {
    CardDefaultStatusEnum,
    LabelItemType,
    TeamChatData,
} from '../../types';
import { getActivitySubjectName } from '../../utils';
import { useGetMatchedWorkspaceId } from '../../hooks';
import { useAtom } from 'jotai';
import { moveTaskToEnd, tasksAtom } from '@atoms/tasksAtom';
import { useUpdateBoardCardData } from '../../features/Dashboard/hooks';
import { useUpdateWorkspaceCardStatus } from '@queries/useUpdateWorkspaceCardStatus';
import { useGetWorkspaceCardsStatuses } from '@queries/useGetWorkspaceCardsStatuses';
import {
    ChatItem,
    useChats,
    useLastMessageByChatId,
} from '../../hooks/useChats';
import clsx from 'clsx';

interface ITaskCardSoloProps {
    wrapperProps?: BoxProps;
    isDragging: boolean;
    onRedirect?: VoidFunction;
    cardName: string;
    hasAccess?: boolean;
    chatTelegramId: number;
    companyChatData?: InternalChatMessagePreview | null;
    teamChatData?: TeamChatData | null;
    currentUserTagged?: boolean;
    isTeamChat?: boolean;
    trackedLastTelegramMessageUnixTime?: number | null;
    cardId: number;
    cardStatusId?: number;
    teamTgIds?: number[];
    labels?: LabelItemType[];
    chat?: ChatItem;
    chats: ReturnType<typeof useChats>;
}

const TaskCardSoloComponent = ({
    isDragging,
    cardName,
    onRedirect,
    chatTelegramId,
    companyChatData,
    wrapperProps,
    currentUserTagged,
    trackedLastTelegramMessageUnixTime,
    cardId,
    cardStatusId,
    labels,
    chat,
    chats,
    isTeamChat,
}: ITaskCardSoloProps) => {
    const workspaceId = useGetMatchedWorkspaceId();
    const [tasks, setTasks] = useAtom(tasksAtom);

    const { data: statusesData } = useGetWorkspaceCardsStatuses();
    const { mutateAsync: updateWorkspaceCardStatus } =
        useUpdateWorkspaceCardStatus();
    const { updateBoardCardData } = useUpdateBoardCardData();
    const { message } = useLastMessageByChatId(chat?.id);

    const userName = message?.sender
        ? getActivitySubjectName(message.sender)
        : 'n/a';

    const isLoaded = true;
    const detailsLoaded = !!chat || !chats.isLoading;

    // Move drag-sensitive UI state to refs
    const isDraggingRef = useRef(isDragging);

    // Update ref when isDragging prop changes
    useEffect(() => {
        isDraggingRef.current = isDragging;
    }, [isDragging]);

    const [isArchiveLoading, setIsArchiveLoading] = useState(false);

    const handleArchiveClick = async () => {
        if (cardStatusId === CardDefaultStatusEnum.ARCHIVE || !cardStatusId) {
            return;
        }
        const getStatusId = (targetStatusId: number) => {
            return (
                statusesData?.value?.statuses.find(
                    (x) => x.typeId === targetStatusId,
                )?.id + ''
            );
        };
        const destinationStatusId = getStatusId(CardDefaultStatusEnum.ARCHIVE);
        const sourceStatusId = getStatusId(cardStatusId);

        const sourceIndex = tasks[sourceStatusId + ''].findIndex(
            (x) => x.cardId === cardId,
        );

        setIsArchiveLoading(true);
        const res = await updateWorkspaceCardStatus({
            workspaceId,
            cardId,
            statusId: +destinationStatusId,
        });

        if (!res.success) {
            setTasks(tasks);
            setIsArchiveLoading(false);
            return;
        }
        setTasks(
            moveTaskToEnd({
                tasks,
                sourceStatusId,
                sourceIndex: +sourceIndex,
                destinationStatusId,
            }),
        );
        updateBoardCardData(cardId, +destinationStatusId);
        setIsArchiveLoading(false);
    };

    return (
        <Box
            className={clsx('task-card', isDragging && 'task-card--dragging')}
            w="100%"
            borderRadius="8px"
            borderWidth="1px"
            borderColor="gray.20"
            bg={isDragging ? 'gray.20' : 'gray.10'}
            role="group"
            onClick={() => {
                onRedirect?.();
            }}
            {...wrapperProps}
        >
            <VStack
                spacing={0}
                align="flex-start"
                p="8px 8px 4px 8px"
                borderBottomWidth={companyChatData ? "1px" : "0px"}
                borderBottomColor="transparentLight.5"
            >
                <CardHeader
                    onArchiveClick={handleArchiveClick}
                    title={cardName}
                    isLoaded={isLoaded}
                    chatTelegramId={chatTelegramId}
                    chatAccessHash={chat?.accessHash || ''}
                    cardId={cardId}
                    lastTgChatMessageDate={chat?.lastMessage?.date || null}
                    trackedLastTelegramMessageUnixTime={
                        trackedLastTelegramMessageUnixTime
                    }
                    workspaceId={workspaceId}
                    cachedData={undefined}
                    isArchiveLoading={isArchiveLoading}
                    controlIconsEnabled={!isTeamChat}
                />
                <CardLabels isLoaded={isLoaded} data={labels || []} />

                {!!chat && (
                    <CardBody
                        unreadCount={chat?.unreadCount || 0}
                        messageContent={message?.content || null}
                        name={userName}
                        isLoaded={detailsLoaded}
                        chatId={chat?.id || '1'}
                        chatTelegramId={chatTelegramId}
                        senderData={message?.sender}
                        isEnableFetchSenderAvatar={false}
                    />
                )}

                <LimitedAccessView
                    hasAccess={!!chat} // TODO: check
                    isLoaded={!chats.isLoading}
                />
            </VStack>

            {companyChatData ? (
                <CardFooter
                    isLoaded={isLoaded}
                    // avatar={avatars[`${companyChatData.telegramUserId}`]}
                    currentUserTagged={currentUserTagged}
                    {...companyChatData}
                />
            ) : null}
        </Box>
    );
};

export const TaskCardSolo = memo(TaskCardSoloComponent);

TaskCardSolo.displayName = 'TaskCardSolo';
