/* eslint-disable indent */
import React, { useEffect, useState, useMemo } from 'react';
import { Flex, Center, Button, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { FixedSizeList as List, VariableSizeList } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';
// import InfiniteLoader from 'react-window-infinite-loader';
import { RootStore } from '../../../../redux/createStore';
import {
    Message,
    Action,
    TextareaGroup,
    TasksPreview,
    ActionItems,
} from './components';
import { useInfiniteChatMessages, useMarkChatAsRead } from './queries';
import { trpc } from '@core/api/trpc';
import {
    useSignalREffect,
    invoke,
    SignalRTopics,
    SignalRMethods,
} from '../../../../services';
import { InternalMessage } from '../../types';
import {
    NewInnerChatMessagePayload,
    NewInnerChatMessageActivity,
    DeletedInnerChatMessage,
} from '../../../../types';
import { Loader, EmptyItemsText } from '../../../../components';
import { useGetCardTasks } from '../../queries';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../../constants';
import { useLocation } from 'react-router-dom';
import { getAddedUtcDate } from '../../../../utils';
import { SettingsIcon } from '@chakra-ui/icons';
import { useAiChats, useChatsHistory } from '../../../../hooks/ai/useAiChats';
import { useAtom } from 'jotai';
import { flagsAtom } from '../../../../featureFlags/store';

interface InternalChatProps {
    cardId: number;
    workspaceId?: number;
}

export const InternalChat: React.FC<InternalChatProps> = ({
    cardId,
    workspaceId,
}) => {
    const [flags] = useAtom(flagsAtom);
    useAiChats();
    const { state } = useLocation();
    const trpcQueryClient = trpc.useUtils();
    const queryClient = useQueryClient();
    const toast = useToast();
    const markAsReadMutation = useMarkChatAsRead(workspaceId, cardId);
    const { user } = useSelector((state: RootStore) => state.auth);
    const { signalRConnected } = useSelector(
        (state: RootStore) => state.signalR,
    );

    const { chatTelegramId } = state || {};
    const historyQuery = useChatsHistory();

    const [arrivedMessages, setArrivedMessages] = useState<InternalMessage[]>(
        [],
    );

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
        useInfiniteChatMessages(workspaceId, cardId, arrivedMessages.length);

    const { data: tasksData } = useGetCardTasks(workspaceId, cardId);

    const { mutate: analyzeChat, isPending: isAnalyzingChat } =
        trpc.aiChats.analyzeChat.useMutation({
            onMutate: () => {
                trpcQueryClient.aiChats.getActions.invalidate();
                toast({
                    title: 'Analysis started',
                    description:
                        'The AI is analyzing your chat history. A summary will appear shortly.',
                    status: 'info',
                    duration: 5000,
                    isClosable: true,
                });
            },
            onSuccess: () => {
                toast({
                    title: 'Analysis completed',
                    description: 'The AI has analyzed your chat history.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            },
            onError: (error) => {
                toast({
                    title: 'Analysis failed',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        QueryKeys.GET_CHAT_MESSAGES,
                        cardId,
                        workspaceId,
                    ],
                });
                trpcQueryClient.aiChats.getActions.invalidate();
            },
        });

    const combinedWithIncomingRows = useMemo(
        () => [
            ...arrivedMessages,
            ...(data?.pages?.flatMap((page) => page?.messages || []) || []),
        ],
        [arrivedMessages, data?.pages],
    );

    const handleAnalyzeChat = () => {
        if (!chatTelegramId || !historyQuery.data) {
            toast({
                title: 'Cannot analyze chat',
                description: 'No Telegram chat history available',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const historyText = historyQuery.data[chatTelegramId.toString()];
        if (!historyText) {
            toast({
                title: 'Cannot analyze chat',
                description: 'No chat history found for this chat',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        analyzeChat({
            chatId: cardId.toString(),
            userId: user.id.toString(),
            historyText,
            workspaceId: workspaceId?.toString() || '',
            cardId: cardId.toString(),
        });
    };

    useSignalREffect(
        SignalRTopics.NewInnerChatMessage as string,
        (...args: any[]) => {
            const [message] = args as [NewInnerChatMessagePayload];
            setArrivedMessages((prev) => [
                { ...message, activity: null, messageType: 1 },
                ...prev,
            ]);
            if (workspaceId) {
                markAsReadMutation.mutate(getAddedUtcDate(1, 'second'));
            }
            console.log('NewInnerChatMessage message', message);
        },
        [workspaceId],
    );

    useSignalREffect(
        SignalRTopics.DeletedInnerChatMessage as string,
        (...args: any[]) => {
            const [deletedMessage] = args as [DeletedInnerChatMessage];
            const newMessageIds =
                arrivedMessages?.map((message) => message.id) || [];
            if (newMessageIds.includes(deletedMessage.messageId)) {
                setArrivedMessages((prev) =>
                    prev.filter(
                        (message) => message.id !== deletedMessage.messageId,
                    ),
                );
                return;
            }

            const oldMessageIds =
                data?.pages
                    ?.flatMap((page) => page?.messages)
                    ?.map((message) => message.id) || [];
            if (!oldMessageIds.includes(deletedMessage.messageId)) {
                return;
            }
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_CHAT_MESSAGES, cardId, workspaceId],
            });
        },
        [arrivedMessages, cardId, workspaceId, data],
    );

    useSignalREffect(
        SignalRTopics.NewInnerChatMessageActivity as string,
        (...args: any[]) => {
            const [message] = args as [NewInnerChatMessageActivity];
            console.log('NewInnerChatMessageActivity message', message);
            setArrivedMessages((prev) => [
                {
                    activity: message,
                    avatarThumbnailUrl: null,
                    id: message.messageId,
                    messageType: 2,
                    senderId: 0,
                    senderName: '',
                    sentAt: '',
                    telegramUserId: 0,
                    text: null,
                    taggedUsers: [],
                },
                ...prev,
            ]);

            if (workspaceId && message.authorInfo.id !== user.id) {
                markAsReadMutation.mutate(getAddedUtcDate(1, 'second'));
            }
            if (
                message.authorInfo.id !== user.id &&
                message.subjectInfo?.id === user.id
            ) {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
                });
            }
        },
        [cardId, workspaceId, user.id],
    );

    useEffect(() => {
        if (workspaceId) {
            markAsReadMutation.mutate();
        }
    }, [workspaceId]);

    useEffect(() => {
        if (signalRConnected) {
            invoke(
                SignalRMethods.WorkspaceCardJoinChat,
                workspaceId || 0,
                cardId || 0,
            );
        }

        return () => {
            invoke(
                SignalRMethods.WorkspaceCardLeaveChat,
                workspaceId || 0,
                cardId || 0,
            );
        };
    }, [signalRConnected, workspaceId, cardId]);

    const calculatedHeight = useMemo(
        () =>
            `calc(100vh - 180px - ${(tasksData?.value?.yourTasks.length || 0) > 3 ? '150px' : `${(tasksData?.value?.yourTasks.length || 0) * 50}px`})`,
        [tasksData],
    );

    return (
        <Flex flexDirection="column" height="100%" flex={1} position="relative">
            {flags.ai_actions && (
                <Flex justifyContent="flex-end" p={4}>
                    <Button
                        colorScheme="blue"
                        size="sm"
                        onClick={handleAnalyzeChat}
                        leftIcon={<SettingsIcon />}
                        isLoading={isAnalyzingChat}
                        isDisabled={
                            !historyQuery.data?.[chatTelegramId.toString()]
                        }
                    >
                        Analyze Chat
                    </Button>
                </Flex>
            )}
            <TasksPreview cardId={cardId} workspaceId={workspaceId} />
            {workspaceId && flags.ai_actions && (
                <ActionItems cardId={cardId} workspaceId={workspaceId || 0} />
            )}
            {combinedWithIncomingRows.length ? (
                <Flex
                    id="messages-section"
                    overflowY="auto"
                    h="100%"
                    direction="column-reverse"
                    minH="240px"
                    sx={{
                        scrollbarWidth: 'thin',
                    }}
                >
                    <InfiniteScroll
                        dataLength={combinedWithIncomingRows.length}
                        refreshFunction={fetchNextPage}
                        next={fetchNextPage}
                        hasMore={hasNextPage}
                        loader={null}
                        scrollableTarget="messages-section"
                        inverse={true}
                        scrollThreshold={200}
                        style={{
                            display: 'flex',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                            gap: '20px',
                            flexDirection: 'column-reverse',
                        }}
                    >
                        {isPending && !isFetchingNextPage ? (
                            <Loader
                                centerHeight={calculatedHeight}
                                centerProps={{ flexShrink: 0 }}
                            />
                        ) : (
                            <>
                                {combinedWithIncomingRows.map((elem) => {
                                    if (
                                        elem.messageType === 2 &&
                                        elem.activity
                                    ) {
                                        return (
                                            <Action
                                                key={elem.id}
                                                cardId={cardId}
                                                workspaceId={workspaceId || 0}
                                                messageId={elem.id}
                                                currentUserId={user.id}
                                                isAdmin={
                                                    user.workSpace?.isAdmin
                                                }
                                                {...elem.activity}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Message
                                                key={elem.id}
                                                {...elem}
                                                isMine={
                                                    user.id === elem.senderId
                                                }
                                                cardId={cardId}
                                                workspaceId={workspaceId || 0}
                                                chatTelegramId={
                                                    chatTelegramId || 0
                                                }
                                                userTelegramId={
                                                    user.telegramUserId
                                                }
                                            />
                                        );
                                    }
                                })}
                                {isFetchingNextPage ? (
                                    <Loader
                                        centerHeight="80px"
                                        spinnerSize="sm"
                                    />
                                ) : null}
                            </>
                        )}
                    </InfiniteScroll>
                </Flex>
            ) : (
                <Center height={calculatedHeight} flex={1}>
                    <EmptyItemsText
                        text="No messages yet"
                        wrapperProps={{ flex: 0.8 }}
                    />
                </Center>
            )}
            <TextareaGroup cardId={cardId} workspaceId={workspaceId} />
        </Flex>
    );
};
