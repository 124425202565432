import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useSteps,
} from '@chakra-ui/react';

import css from './index.module.css';

import OnboardingCreateWorkspace from './create-workspace';
import OnboardingInviteTeam from './invite-team';
import OnboardingSyncChats from './sync-chats';

const steps = [
    {
        key: 'create-workspace',
        title: 'Create your workspace',
        Content: OnboardingCreateWorkspace,
    },
    {
        key: 'invite-team',
        title: 'Invite your team',
        Content: OnboardingInviteTeam,
    },
    { 
        key: 'sync-chats', 
        title: 'Chats sync', 
        Content: OnboardingSyncChats
    },
];

function CreateWorkspaceNew() {
    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: steps.length,
    });

    const currentStep = steps[activeStep];

    const CurrentStepComponent = currentStep.Content;

    const handleCancel = () => {};

    return (
        <Modal
            isOpen={true}
            isCentered={true}
            onClose={handleCancel}
        >
            <ModalOverlay />

            <ModalContent className={css.content}>
                <ModalBody className={css.body}>
                    <CurrentStepComponent
                        key={currentStep.key}
                        goToNext={goToNext}
                        goToPrevious={goToPrevious}
                        cancel={handleCancel}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default CreateWorkspaceNew;
