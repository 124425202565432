import React from 'react';
import { Box, Flex, HStack, Heading, Skeleton, Text } from '@chakra-ui/react';
import { LabelsSection, LastActivitySection } from './sections';
import { LabelItemType } from '../../../../types';
import { OwnerSection } from './sections/Owner.section';
import { trpc } from '@core/api/trpc';
import { ChatInfoSection } from './sections/ChatInfo.section';

interface InfoProps {
	cardTitle: string;
	cardId: number;
	workspaceId: number;
	isCardDataLoading: boolean;
	cardLabels: LabelItemType[];
	chat: any;
}

export const Info: React.FC<InfoProps> = ({
	cardTitle,
	workspaceId,
	cardId,
	isCardDataLoading,
	cardLabels,
	chat,
}) => {
	
	const cardOwnerQuery = trpc.cards.getCardAndOwner.useQuery({
		cardId: cardId,
	});

	const updateCardChatInfo = trpc.cards.updateCardChatInfo.useMutation();

	const onInfoChange = async (info: string) => {
		await updateCardChatInfo.mutateAsync({
			cardId: cardId,
			chatInfo: info,
		});
	};

	return (
		<Box p="24px">
			<Flex alignItems="center" justify="space-between" mb="16px">
				<Skeleton
					isLoaded={!isCardDataLoading}
					borderRadius="4px"
					startColor="gray.40"
					endColor="gray.20"
					w={isCardDataLoading ? '200px' : 'auto'}>
					<HStack spacing={2}>
						<Heading
							color="primary"
							fontSize="16px"
							fontWeight="600"
							lineHeight="19.5px"
							noOfLines={1}>
							{cardTitle}
						</Heading>
						<Text fontWeight={600} fontSize="16px" color="gray.35">
							Information
						</Text>
					</HStack>
				</Skeleton>
			</Flex>
			{cardOwnerQuery.data && (
				<>
					<ChatInfoSection
						cardId={cardId}
						cardOwnerQuery={cardOwnerQuery}
						onInfoChange={onInfoChange}
					/>
					<OwnerSection
						cardOwnerQuery={cardOwnerQuery}
					/>
				</>
			)}
			<LabelsSection
				workspaceId={workspaceId}
				cardId={cardId}
				cardLabels={cardLabels}
			/>
			<LastActivitySection
				chat={chat}
			/>
		</Box>
	);
};

