import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { LegacyApiResponse } from '../axios';

type SetCurrentWorkspacePayload = {
    workspaceId: number;
}

type SetCurrentWorkspaceResult = {
    invitedUsersIds: number[];
    notInvitedUsers: string[];
}

const setCurrentWorkspace = async (workspaceId: number) => {
	const { data } = await axios.put<LegacyApiResponse<any>>(
		`/api/workspaces/current`, { workspaceId: workspaceId }
	);

	return data.value || data;
};

const MUTATION_KEY_SET_CURRENT_WORKSPACE = 'MUTATION_KEY_SET_CURRENT_WORKSPACE';

function useSetCurrentWorkspaceMutation() {
    return useMutation<SetCurrentWorkspaceResult,unknown, SetCurrentWorkspacePayload>({
        mutationKey: [MUTATION_KEY_SET_CURRENT_WORKSPACE],
        mutationFn: ({ workspaceId }) => setCurrentWorkspace(workspaceId),
    });
}
 
export { 
    setCurrentWorkspace, 
    useSetCurrentWorkspaceMutation,
    MUTATION_KEY_SET_CURRENT_WORKSPACE 
};
