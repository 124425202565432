import { useSelector } from 'react-redux';
import { memo, PropsWithChildren } from 'react';
import { Navigate, Path } from '@router';
import { useLocation } from 'react-router-dom';
import { RootStore } from '../redux/createStore';

function AuthRedirects({ children }: PropsWithChildren) {
	const location = useLocation();

	const pathname = location.pathname as Path;

	const {	accessToken } = useSelector((state: RootStore) => state.auth.token);

	const { isSynced } = useSelector((state: RootStore) => state.syncState);
	
	console.log('Auth-Redirects:', { pathname, accessToken, isSynced });

	if (pathname !== '/login' && !accessToken) return <Navigate to={'/login'} replace />;

	if (pathname === '/login' && accessToken ) return <Navigate to={'/'} replace />;

	return children;
}

export default memo(AuthRedirects);
