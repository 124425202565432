import React, { MouseEvent } from 'react';
import { HStack, Flex, Text, Button } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootStore } from '../../redux/createStore';
import {
	Icon,
	CustomAvatarGroup,
	InviteMembersButton,
	CommandMenu,
} from '../../components';
import { setSyncSettingsModalOpen } from '../../redux/reducers/onboarding.reducer';
import { useGetWorkspaceMembers } from '../../queries';
import { useGetMatchedWorkspaceId } from '../../hooks';
import { useModals } from '@router';

interface DefaultPageHeaderProps {
	title: string;
	iconName?: string;
	isSyncSettingsEnabled?: boolean;
	isInviteMembersEnabled?: boolean;
}

export const DefaultPageHeader: React.FC<DefaultPageHeaderProps> = ({
	title,
	iconName,
	isSyncSettingsEnabled,
	isInviteMembersEnabled,
}) => {
	const dispatch = useDispatch();
	const modals = useModals();

	const avatars = useSelector((state: RootStore) => state.avatars);
	
	const { workSpace } = useSelector((state: RootStore) => state.auth.user);

	const workspaceId = useGetMatchedWorkspaceId();

	const { data: workspaceMembers } = useGetWorkspaceMembers();
	const handleOpenSyncSettings = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        modals.open('/sync-settings', {
            at: '/:workspaceId',
            params: { workspaceId: `${workspaceId}` },
        });
    };    

	return (
		<Flex 
			as='header' 
			id="default-header"
			align="center" 
			mb='0.75rem'
		>
			<HStack
				spacing={1}
				align="center"
				h="20px"
				width="100%"
				minW="150px"
				maxWidth="calc((100vw / 2) - 240px - 184px)"
			>
				{iconName ? (
					<Icon name={iconName} width="16px" height="16px" />
				) : null}

				<Text noOfLines={1}>{title}</Text>
			</HStack>

			<CommandMenu />

			<HStack spacing={0} flex={1} pl="15px" justify="flex-end">
				{workSpace?.isAdmin ? (
					<Button
						leftIcon={
							<Icon name="edit" height="16px" width="16px" />
						}
						variant="outline"
						as={Link}
						to={`/${workspaceId}/settings/pipeline`}
						bg="gray.10"
						size="sm"
						height="24px"
						px={2}
						iconSpacing={1}
						color="secondary"
						borderRadius="4px"
						mr="16px">
						Customize
					</Button>
				) : null}

				{isSyncSettingsEnabled ? (
					<Button
						leftIcon={
							<Icon name="refresh" height="14px" width="14px" />
						}
						mr="16px"
						variant="outline"
						bg="gray.10"
						color="secondary"
						size="sm"
						iconSpacing={1}
						px={2}
						height="24px"
						borderRadius="4px"
						onClick={handleOpenSyncSettings}>
						Sync settings
					</Button>
				) : null}

				<CustomAvatarGroup
					items={workspaceMembers?.value?.members || []}
					avatars={avatars}
				/>

				{workSpace?.isAdmin && isInviteMembersEnabled ? (
					<InviteMembersButton />
				) : null}
			</HStack>
		</Flex>
	);
};
