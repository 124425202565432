import { Box} from '@chakra-ui/react';
import { useTelegram } from '../../services';
import { RootStore } from '../../redux/createStore';
import { useSelector } from 'react-redux';

const TelegramSettingsPage = () => {
	const tg = useTelegram();
	const { isSynced } = useSelector((state: RootStore) => state.syncState);
	if (tg && isSynced) {
		tg.custom.proxy.simulateEscapeKeyPress();
		tg.custom.proxy.openSettingsButton();
		tg.iframe.contentWindow?.focus();
	}

	return (
		<Box 
			as='section' 
			id='tg-settings-layout' 
			w="100%" 
			h='100%'
			position='relative'
		>

			
		
		</Box>
	);
};

export default TelegramSettingsPage;