import { Box, Button, Center, Checkbox, Flex, Icon, Text } from "@chakra-ui/react";

import { OnboardingScreenProps } from "../types";
import { ChangeEvent, useState } from "react";
import { IconX } from "@tabler/icons-react";
import { WarningBannerWrapper } from "../../../modals/Onboarding/components";

import css from './index.module.css'

type Permission = {
    id: string;
    title: string;
    description: string;
    value: boolean;
};

const weAreNotStoring: Permission[] = [
    {
        id: 'credentials',
        title: 'Your credentials and auth token',
        description: "Authentication happens directly through Telegram, we can't access your account",
        value: false,
    },
    {
        id: 'chat-storage',
        title: 'Reading and storing your chats',
        description: 'All the chats and recent updates are synced and displayed locally in your browser',
        value: false,
    },
];

const weNeedPermissionsToStore: Permission[] = [
    {
        id: 'chats-list',
        title: 'List of chats and folders',
        description: 'Required for import and sync of chats to work',
        value: false,
    },
    {
        id: 'telegram-ids',
        title: "Telegram ID's of users and chats",
        description: 'Required to store and match entries in the database',
        value: false,
    },
    {
        id: 'last-activity',
        title: 'Last activity in the chat',
        description: 'Required for filters "Unasnwered" and "Last Activity" to work',
        value: false,
    },
];

function OnboardingPrivacyControl({ goToNext }: OnboardingScreenProps) {
    const [permissions, setPermissions] = useState<Permission[]>(weNeedPermissionsToStore);

    const handleCheck = (id: string, value: boolean) => {
        setPermissions((prev) => prev.map((permission) => {
                if (permission.id !== id) return permission;

                return { ...permission, value };
            })
        );
    }

    const isAllChecked = permissions.every((p) => p.value);
        
    return (
        <Flex 
            direction='column' 
            alignItems={'center'} 
            gap='2rem'
        >
            <Box>
                <Text
                    fontWeight='600'
                    fontSize='20px'
                    lineHeight='24px'
                    textAlign='center'
                    mb='1rem'
                >
                    Privacy control
                </Text>

                <Text color='secondary'>
                    Control which data Dise can access and store
                </Text>
            </Box>

            <Flex w='20rem' direction='column' gap='20px'>
                {permissions.map((permission) => (
                    <CustomCheckbox
                        key={permission.id}
                        data={permission}
                        onChange={(e) => handleCheck(permission.id, e.target.checked)}
                    />
                ))}

                {weAreNotStoring.map((item) => (
                    <CustomCheckbox
                        key={item.id}
                        data={item}
                        withCustomIcon
                    />
                ))}
            </Flex>

            <WarningBannerWrapper
                iconName="exclamation-mark-circle"
                wrapperProps={{ visibility: isAllChecked ? "hidden" : 'visible' }}
            >
                <Box>
                    <Text fontWeight="500" color="secondary">
                        Required approvals are missing.
                    </Text>

                    <Text color="secondary">
                        If you want to benefit from the seamless integration
                        with Telegram and not to update everything by hand
                        like in old days you need to enable core data
                        access.
                    </Text>
                </Box>
            </WarningBannerWrapper>

            <Center>
                <Button
                    variant='outline'
                    isDisabled={!isAllChecked}
                    boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
                    onClick={goToNext}
                >
                    Approve
                </Button>
            </Center>
        </Flex>
    );
}

interface CustomCheckbox {
    data: Permission;
    withCustomIcon?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};
const CustomCheckbox = ({ data, withCustomIcon, onChange }: CustomCheckbox) => {
    const { id, title, description, value } = data;

    return (
        <Checkbox
            id={id}
            name={title}
            isChecked={value}
            icon={withCustomIcon ? <CustomIcon /> : undefined}
            onChange={onChange}
            className={css.checkbox}
        >
            <Box>
                <Text fontWeight="500" fontSize="13px" color="primary">
                    {title}
                </Text>
                <Text fontWeight="400" fontSize="11px" color="tertiary">
                    {description}
                </Text>
            </Box>
        </Checkbox>
    );
};

const CustomIcon = (props: any) => {
    const { isIndeterminate, isChecked, ...rest } = props
  
    return (
        <Icon bg='gray.35' borderRadius='1rem' h='1rem' w='1rem' as={IconX} {...rest} />
    )
  }

export default OnboardingPrivacyControl;
