import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../../api/api.base';
import { ApiResponse, OnboardingSteps } from '../../../types';
import {
    setOnboardingActiveStep,
    setCreateWorkspaceModalOpen,
} from '../../../redux/reducers/onboarding.reducer';
import { Alerter } from '../../../utils';
import { QueryKeys } from '../../../constants';
// import { setWorkspaceId } from '../../../redux/reducers/auth.reducer';
import { requestPromise, resetRequestPromise } from '../../../hooks/useChats';

export const useCreateWorkspace = (isIsolated: boolean) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [newWorkspaceId, setNewWorkspaceId] = useState<number | null>(null);

    const mutation = useMutation<ApiResponse<number>, unknown, FormData>({
        mutationKey: [QueryKeys.CREATE_WORKSPACE],
        mutationFn: (payload) => API.post('/api/workspaces', payload),
        onSuccess: async (data) => {
            if (!data.success) {
                Alerter.error(
                    data.errors?.[0]?.message ||
                        'Error occurred while creating workspace',
                );
                return;
            }

            if (!isIsolated) {
                dispatch(setOnboardingActiveStep(OnboardingSteps.INVITE_TEAM));
            }

            dispatch(setCreateWorkspaceModalOpen(false));
            // dispatch(setWorkspaceId(data.value));

            await queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_WORKSPACES],
            });

            setNewWorkspaceId(data.value);
        },
        onSettled() {
            resetRequestPromise();
            queryClient.resetQueries();
        },
    });

    //*Don't change it, otherwise navigation will not work properly after workspace is created
    useEffect(() => {
        if (newWorkspaceId) {
            navigate(`/${newWorkspaceId}`, { replace: true });
        }
    }, [newWorkspaceId, navigate]);

    return mutation;
};
