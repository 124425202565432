import React from 'react';
import { MenuItem, Text } from '@chakra-ui/react';
import { Icon } from '../../../Icon/Icon';
import { colors } from '../../../../theme/colors';
import { useModals } from '@router';
import { useGetMatchedWorkspaceId } from '../../../../hooks';

export const AddWorkspaceButton: React.FC = () => {
	const modals = useModals();
	const workspaceId = useGetMatchedWorkspaceId();
	
	const handleClick = () => {
		modals.open('/create-workspace', {
            at: '/:workspaceId',
            params: { workspaceId: `${workspaceId}` },
        });
	};

	return (
		<MenuItem
			height="40px"
			onClick={handleClick}
			px="8px"
			gap="8px"
			_hover={{ bg: 'transparentLight.10' }}
			_focus={{ bg: 'transparentLight.10' }}
		>
			<Icon
				name="plus"
				height="16px"
				width="16px"
				color={colors.secondary}
			/>
			<Text color="secondary" fontSize="13px" fontWeight={400}>
				Add workspace
			</Text>
		</MenuItem>
	);
};
