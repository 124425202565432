import {  Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import Selector from './selector';

import css from './index.module.css'

const SelectWorkspaceNew = () => {
    return (
        <Modal
            isOpen={true}
            isCentered={true}
            onClose={() => {}}
        >
            <ModalOverlay />

            <ModalContent className={css.content}>
                <ModalHeader className={css.header}>
                    Select a workspace
                </ModalHeader>

                <ModalBody className={css.body}>
                    <Selector />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SelectWorkspaceNew;
