import { FlagsConfig } from './schema';

export const defaultConfig: FlagsConfig = {
    development: {
        batch_send: false,
        new_auth: false,
        danger_zone_visible: true,
        ai_actions: true,
    },
    production: {
        batch_send: false,
        new_auth: false,
        danger_zone_visible: false,
        ai_actions: false,
    },
    testing: {
        batch_send: false,
        new_auth: false,
        danger_zone_visible: true,
        ai_actions: true,
    },
};
