import { useEffect } from 'react';
import { useGetCurrentUserWorkspaceFoldersQuery } from '@core/api/queries/current-user-workspace-folders';
import { useGetWorkspaceId } from '../hooks';
import { useTelegramLock } from '../services/telegram/lockContext';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';
import { useGetCurrentUserQuery, useSyncChatsMutation } from '@core/api';
import { FolderChatSyncChatsMutationPayload } from '@core/api/mutations/sync-chats';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../constants';
import { allowedForSyncChatTypes } from '../constants';
export const useAutoSync = () => {
    const queryClient = useQueryClient();
    const lock = useTelegramLock();
    const { isSynced } = useSelector((state: RootStore) => state.syncState);
    const workspaceId = useGetWorkspaceId();
    const getCurrentUserQuery = useGetCurrentUserQuery();
    const syncChatsMutation = useSyncChatsMutation();
    const { data: userFolders } = useGetCurrentUserWorkspaceFoldersQuery(workspaceId.toString());
    useGetBoardCards();
    useEffect(() => {
        if (!isSynced || !userFolders?.folders|| getCurrentUserQuery.data?.isOnboarded) return;

        lock.scheduleBackgroundEvent(async tg => {
            try {
                const chatFolders = await tg.methods.proxy.fetchChatFolders();
                if (!chatFolders) return;

                const selectedChatFoldersIds = userFolders.folders.map(folder => folder.telegramFolderId);

                const chatsIds = selectedChatFoldersIds.flatMap((folderId) => {
                    return chatFolders.byId[folderId].includedChatIds;
                });

                const resolvedChatsInfos = await Promise.allSettled(
                    chatsIds.map((chatId) => tg.custom.proxy.getChatById(Number(chatId)))
                );

                const chatsByIds = resolvedChatsInfos.reduce<Record<string, any>>((acc, chat) => {
                    if (chat.status === 'fulfilled' && 
                        chat.value?.chatShortInfo && 
                        allowedForSyncChatTypes.includes(chat.value.chatShortInfo.type)) {
                        acc[chat.value.chatShortInfo.id] = chat.value.chatShortInfo;
                    }
                    return acc;
                }, {});

                const foldersPayload = selectedChatFoldersIds.map((folderId) => {      
                    return {
                        telegramFolderId: folderId,
                        chats: chatFolders.byId[folderId].includedChatIds.reduce<FolderChatSyncChatsMutationPayload[]>((acc, chatId) => {
                            if (!chatsByIds[chatId]) return acc;
                            return acc.concat([{
                                telegramChatId: chatId,
                                name: chatsByIds[chatId].title || 'Unknown chat name',
                            }]);
                        }, [])
                    };
                });

                await syncChatsMutation.mutateAsync({
                    workspaceId: workspaceId,
                    folders: foldersPayload
                });


                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_BOARD_CARDS],
                });
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.TG_CHATS, workspaceId.toString(), "individual"],
                });
                await getCurrentUserQuery.refetch();

            } catch (error) {
                console.error('Auto sync failed:', error);
            }
        });
    }, [isSynced, userFolders?.folders]);
};