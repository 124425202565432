import { BoardTabsType, BoardFilterValue } from '../types';

export const getBoardTabs = (params?: {
	unread: number;
	unanswered: number;
	teamActivity: number;
	activeTasks: number;
	mentions: number;
}): BoardTabsType[] => [
	{
		id: 1,
		label: 'All Chats',
		filterValue: BoardFilterValue.All,
	},
	{
		id: 2,
		label: 'Unread',
		activity: params?.unread || 0,
		activityBgColor: 'red.90',
		activityTextColor: 'white',
		filterValue: BoardFilterValue.Unread,
	},
	{
		id: 3,
		label: 'Unanswered',
		activity: params?.unanswered || 0,
		activityBgColor: 'blue.DEFAULT',
		activityTextColor: 'white',
		filterValue: BoardFilterValue.Unanswered,
	},
	{
		id: 4,
		label: 'Team Activity',
		activity: params?.teamActivity || 0,
		iconName: 'users',
		activityBgColor: 'transparentLight.10',
		activityTextColor: 'secondary',
		filterValue: BoardFilterValue.TeamActivity,
	},
	{
		id: 5,
		label: 'Active Tasks',
		activity: params?.activeTasks || 0,
		iconName: 'progress-check',
		activityBgColor: 'transparentLight.10',
		activityTextColor: 'secondary',
		filterValue: BoardFilterValue.ActiveTasks,
	},
	{
		id: 6,
		label: 'Mentions',
		activity: params?.mentions || 0,
		iconName: 'at',
		activityBgColor: 'transparentLight.10',
		activityTextColor: 'secondary',
		filterValue: BoardFilterValue.Mentions,
	},
];
