import { useMutation } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';
import { useGetCurrentUserQuery } from '@core/api';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '../../../redux/reducers/auth.reducer';

export const useSetTeamChat = () => {
    const dispatch = useDispatch();
	const getCurrentUserQuery = useGetCurrentUserQuery();

	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ workspaceId: number; telegramChatId: number; name: string }
	>({
		mutationKey: [QueryKeys.SET_TEAM_CHAT],
		mutationFn: payload => {
			const { workspaceId, ...bodyPayload } = payload;
			return API.post(
				`/api/workspaces/${workspaceId}/team-chat`,
				bodyPayload,
			);
		},

		onSuccess: async (data) => {
			if (!data.success) {
				return Alerter.error(
					data?.errors?.[0].message ||
						'Error when adding command chat',
				);
			}

			// Temporal solution, remove later
			const currentUser = await getCurrentUserQuery.refetch();
			if (!currentUser?.data) return;
			dispatch(setAuthUser(currentUser.data))
			// 

			Alerter.success('The team chat has been successfully added!');
		},
	});
};
