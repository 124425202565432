import { Outlet } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { useSelector, useDispatch } from 'react-redux';
import { setConnectionState } from '../../redux/reducers/signal-r.reducer';
import { RootStore } from '../../redux/createStore';
import {
    useGetWorkspaceCardsStatuses,
    useGetWorkspaceMembers,
    useGetWorkspaceTelegramIds,
    useGetUserApiTgFolders,
} from '../../queries';
import { SignalRProvider } from '../../services';
import { useSubscribeToEvents } from './hooks';
import { useGetWorkspaceId } from '../../hooks';
import { useGetBoardCards } from '@features/Dashboard/queries';

// RENAME to "WithSignalR" and clear side-effects
export const MainLayout = () => {
    const dispatch = useDispatch();
    const workspaceId = useGetWorkspaceId();

    // const { user } = useSelector((state: RootStore) => state.auth);
    const { accessToken } = useSelector((state: RootStore) => state.auth.token);

    useGetBoardCards();
    useGetWorkspaceCardsStatuses(!!workspaceId);
    useGetWorkspaceMembers(!!workspaceId);
    useGetUserApiTgFolders();
    useSubscribeToEvents();

    // Needed for filters on dashboard, possible to refactor
    useGetWorkspaceTelegramIds();

    // const setUserTimeZoneMutation = useSetUserTimeZone();


    // NOTE: Disabled analitics for now, fix it later more smarter 
    // const analytics = useAnalytics();

    // useEffect(() => {
    //     if (
    //         analytics &&
    //         tg &&
    //         isSynced &&
    //         data?.value?.workSpace &&
    //         !isLoading
    //     ) {
    //         const workspace = data.value.workSpace;

    //         analytics.group(workspace.id + '', {
    //             name: workspace.name,
    //             avatarUrl: workspace.avatarUrl,
    //             workspaceId: workspace.id,
    //         });
    //     }
    // }, [tg, isSynced, data, isLoading, analytics]);

    // useEffect(() => {
    //     if (analytics && workspaceId && data?.value?.workSpace && !isLoading) {
    //         analytics.track('DASHBOARD_VIEWED', {
    //             workspaceId: workspaceId,
    //         });
    //     }
    // }, [workspaceId, data, isLoading, analytics]);

    // useEffect(() => {
    //     if (!isLoading && data?.value) {
    //         dispatch(setAuthUser(data.value));
    //     }
    //     if (!isLoading) {
    //         const currentUserTimeZone = getCurrentUserTimeZone();
    //         if (
    //             !data?.value?.timeZoneId ||
    //             data?.value?.timeZoneId !== currentUserTimeZone
    //         ) {
    //             setUserTimeZoneMutation.mutate(currentUserTimeZone);
    //         }
    //     }
    // }, [data, isLoading, dispatch, setUserTimeZoneMutation]);

    // useEffect(() => {
    //     dispatch(resetChatsIsRefetchDisabledState());
    // }, [dispatch]);

    return (
        <SignalRProvider
            connectEnabled={!!accessToken}
            accessTokenFactory={() => accessToken}
            dependencies={[accessToken]}
            url={import.meta.env.VITE_API_BASE_URL + '/hub'}
            skipNegotiation={true}
            onOpen={() => {
                dispatch(setConnectionState(true));
                console.log('[SignalR] connect open');
            }}
            onReconnect={() => {
                console.log('[SignalR] reconnect');
                dispatch(setConnectionState(true));
            }}
            onClosed={(error) => {
                dispatch(setConnectionState(false));
                console.log(error, '[SignalR] signalR closed');
            }}
            onError={async (error) => {
                console.log(error, '[SignalR] signal R onError');
            }}
            // logger={signalR.LogLevel.Trace}
            logMessageContent={true}
            transport={signalR.HttpTransportType.WebSockets}
        >
            <Outlet />
        </SignalRProvider>
    );
};
