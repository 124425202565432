import { useEffect, useState } from 'react';
import { FeatureFlagsPanel } from './FeatureFlagsPanel';

const DEBUG_FLAG_KEY = 'debug_mode';

export const DevTools = () => {
    const [isDebugMode, setIsDebugMode] = useState(false);

    useEffect(() => {
        const debugMode = localStorage.getItem(DEBUG_FLAG_KEY) === 'true';
        setIsDebugMode(debugMode);

        const handleStorage = (e: StorageEvent) => {
            if (e.key === DEBUG_FLAG_KEY) {
                setIsDebugMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleStorage);
        return () => window.removeEventListener('storage', handleStorage);
    }, []);

    if (!isDebugMode) return null;

    return <FeatureFlagsPanel />;
};
