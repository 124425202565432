import { useAtom } from 'jotai';
import { flagsAtom } from '../../featureFlags/store';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Switch,
    VStack,
    HStack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';

export const FeatureFlagsPanel = () => {
    const [flags, setFlags] = useAtom(flagsAtom);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const toggleFlag = (key: keyof typeof flags) => {
        setFlags({ ...flags, [key]: !flags[key] });
    };

    return (
        <>
            <Button
                position="fixed"
                bottom={12}
                right={4}
                colorScheme="blue"
                onClick={onOpen}
            >
                Feature Flags
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Feature Flags</ModalHeader>
                    <ModalBody pb={6}>
                        <VStack spacing={4} align="stretch">
                            {Object.entries(flags).map(([key, value]) => (
                                <HStack key={key} justify="space-between">
                                    <Text fontWeight="medium">{key}</Text>
                                    <Switch
                                        isChecked={value}
                                        onChange={() =>
                                            toggleFlag(
                                                key as keyof typeof flags,
                                            )
                                        }
                                    />
                                </HStack>
                            ))}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
